export const APPS = {
    SERVICE: { id: '1', name: 'Service Manager', short: 'Service', requestType: 'service_request', serviceTypes: ['service_request'] },
    ASSETS: { id: '2', name: 'Asset Manager', short: 'Assets', requestType: 'asset_request', serviceTypes: ['asset_request', 'asset_record'] },
    LOGISTICS: { id: '3', name: 'Logistics', short: 'Logistics' },
};
export const ADDRESSES = {
    BILLING: { id: '1', name: 'Billing', source: ['customers', 'vendors'] },
    SERVICE: { id: '2', name: 'Service', source: ['customers'] },
    DELIVERY: { id: '3', name: 'Delivery', source: ['customers', 'vendors'] },
    PHYSICAL: { id: '4', name: 'Physical', source: ['customers', 'employees'] },
    MAILING: { id: '5', name: 'Mailing', source: ['customers', 'employees'] },
};
export const CHECKIN = {
    CHECKOUT: { id: '1', name: 'CheckOut', color: '#b3b3b3' },
    ENROUTE: { id: '2', name: 'Enroute', color: '#679cca' }, // id & color matches WORKORDERS
    ONSITE: { id: '3', name: 'Onsite', color: '#937eca' }, // id & color matches WORKORDERS
    INPROGRESS: { id: '4', name: 'In Progress', color: '#FF8C00' }, // id & color matches WORKORDERS
};
export const CONTACTS = {
    BILLING: { id: '1', name: 'Billing' },
    CONTACT: { id: '2', name: 'Contact' },
    MANAGER: { id: '3', name: 'Manager' },
    TENANT: { id: '4', name: 'Tenant' },
    OWNER: { id: '5', name: 'Owner' },
};
export const COUNTS = {
    ASSETS_WORKORDERS_UNASSIGNED: { id: 'assetsWorkOrdersUnassigned' },
    ASSETS_WORKORDERS_INCOMPLETE: { id: 'assetsWorkOrdersIncomplete' },
    ASSETS_WORKORDERS_INPROGRESS: { id: 'assetsWorkOrdersInProgress' },
    
    ASSETS_REQUESTS_UNASSIGNED: { id: 'assetRequestsUnassigned' },
    ASSETS_REQUESTS_INPROGRESS: { id: 'assetRequestsInprogress' },
    ASSETS_REQUESTS_COMPLETED: { id: 'assetRequestsCompleted' },
    ASSETS_REQUESTS_INVOICED: { id: 'assetRequestsInvoiced' },
    
    ASSETS_QUOTES_DRAFTS: { id: 'assetQuotesDrafts' },
    ASSETS_QUOTES_PENDING: { id: 'assetQuotesPending' },
    ASSETS_QUOTES_DECLINED: { id: 'assetQuotesDeclined' },
    ASSETS_QUOTES_ACCEPTED: { id: 'assetQuotesAccepted' },
    
    SERVICE_WORKORDERS_UNASSIGNED: { id: 'serviceWorkOrdersUnassigned' },
    SERVICE_WORKORDERS_INCOMPLETE: { id: 'serviceWorkOrdersIncomplete' },
    SERVICE_WORKORDERS_INPROGRESS: { id: 'serviceWorkOrdersInProgress' },
    
    SERVICE_REQUESTS_UNASSIGNED: { id: 'serviceRequestsUnassigned' },
    SERVICE_REQUESTS_INPROGRESS: { id: 'serviceRequestsInprogress' },
    SERVICE_REQUESTS_COMPLETED: { id: 'serviceRequestsCompleted' },
    SERVICE_REQUESTS_INVOICED: { id: 'serviceRequestsInvoiced' },

    SERVICE_QUOTES_DRAFTS: { id: 'serviceQuotesDrafts' },
    SERVICE_QUOTES_PENDING: { id: 'serviceQuotesPending' },
    SERVICE_QUOTES_DECLINED: { id: 'serviceQuotesDeclined' },
    SERVICE_QUOTES_ACCEPTED: { id: 'serviceQuotesAccepted' },
};
export const CUSTOMERS = {
    RESIDENTIAL: { id: '1', name: 'Residential', badge: 'info', type: 'residential', appIds: [APPS.SERVICE.id, APPS.ASSETS.id] },
    COMMERCIAL: { id: '2', name: 'Commercial', badge: 'primary', type: 'commercial', appIds: [APPS.SERVICE.id, APPS.ASSETS.id] },
    PROPERTY_MANAGEMENT: { id: '3', name: 'Property Management', badge: 'success', type: 'property', appIds: [APPS.SERVICE.id] },
};
export const INVOICES = {
    DRAFT: { id: '1', name: 'Draft', color: '#cccccc', description: 'This invoice in in Draft status and has not been presented to a customer for payment.  A Draft Invoice may be deleted.' },
    SENT: { id: '2', name: 'Sent', color: '#e8e475', description: 'This invoice has been Sent to the Customer and is ready for payment.' },
    PAID: { id: '3', name: 'Paid', color: '#68bc00', description: 'Paid indicates that the invoice has been received and paid in full' },
    PARTIAL: { id: '4', name: 'Partial', color: '#a4dd00', description: 'There has been Partial payment made on this invoice.' },
    OVERDUE: { id: '5', name: 'Overdue', color: 'orange', description: 'The invoice has been sent to the Customer and has passed the due date.' },
    REJECTED: { id: '6', name: 'Rejected', color: 'red', description: 'The Customer has permanently Rejected the invoice.' },
    VOID: { id: '7', name: 'Void', color: '#679cca', description: 'A Voided invoice is considered closed or uncollectable' },
    CLOSED: { id: '8', name: 'Closed', color: 'black', description: 'The Invoice is completed and applied to internal use.' },
};
export const PAYMENTS = {
    PENDING: { id: '1', name: 'Pending', color: '#e8e475' },
    FAILURE: { id: '2', name: 'Failure', color: '#d33115' },
    SUCCESS: { id: '3', name: 'Success', color: '#68bc00' },
    VOID: { id: '4', name: 'Void', color: '#000000' },
};
export const PURCHASE_ORDERS = {
    AUTO: { id: '0', name: 'Auto', color: '#eeeeee', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    DRAFT: { id: '1', name: 'Draft', color: '#cccccc', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    OPEN: { id: '2', name: 'Open', color: '#16a5a5', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    RECEIVED: { id: '3', name: 'Received', color: '#a4dd00', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
    PAID: { id: '4', name: 'Paid', color: '#68bc00', description: 'n/a', change: 'n/a', caution: false, updatable: false, possible: false },
};
export const QUOTES = {
    DRAFT: { id: '0', name: 'Draft', color: '#cccccc', description: 'A "Draft" Quote has been created but has not yet been printed or emailed to the customer for review.  It may be overwritten with a new draft.', change: 'Once a Quote has been presented to the Customer it cannot be set again to "Draft".', caution: false, possible: false },
    PENDING: { id: '1', name: 'Pending', color: '#e8e475', description: 'A Quote with the status "Pending" is awaiting Customer review.  When the Customer responds, it will change to "Accepted" or "Rejected".', change: 'You cannot change the status to "Pending" from here.  Please print or email the Quote to the Customer.', caution: false, possible: false },
    REJECTED: { id: '2', name: 'Rejected', color: '#d33115', description: 'A "Rejected" Quote has been actively rejected by the Customer and no further action can be made.', change: 'Changing the status to "Rejected" indicates that the Customer is not proceeding with the project unless a new Quote is generated and accepted.', caution: true, possible: true },
    CHANGES_REQUESTED: { id: '3', name: 'Changes Requested', color: '#FFA500', description: 'This status means that a Quote has been reviewed by the Customer and the Customer has requested changes.', change: '"Changes Requested" are submitted by the customer after reviewing a Quote.', caution: true, possible: false },
    ACCEPTED: { id: '4', name: 'Accepted', color: '#68bc00', description: 'An "Accepted" Quote is approved by the Customer, changes the status of the Service Request to "Project", and serves as the basis for the completion of the Project', change: 'Changing the status to "Accepted" from here authorizes the Project to continue, but without a Customer signature.', caution: false, possible: true },
    CLOSED: { id: '5', name: 'Closed', color: '#333333', description: 'A Quote with the status "Closed" has been replaced by another Quote,or accepted by the Customer and that project has been subsequently completed or closed as Incomplete.', change: 'An "Accepted" Quote will automatically be set to "Closed" when the associated Service Request is Closed.', caution: false, possible: false },
};
export const REQUESTS = {
    UNASSIGNED: { id: '0', name: 'Unassigned', color: '#b3b3b3', description: 'One or more Work Order associated with this Service Request has not yet been assigned to a technician. This can be done by editing the Work Order, or dragging it to Technician\'s Schedule from the Schedule view.' },
    ASSIGNED: { id: '1', name: 'Assigned', color: '#16a5a5', description: 'All the Work Orders associated with this Service Request have been assigned to a technician.' },
    INPROGRESS: { id: '2', name: 'In Progress', color: '#FF8C00', description: 'One or more Work Order associated with this Service Request has been marked as "In Progress".' },
    INCOMPLETE: { id: '3', name: 'Incomplete', color: '#e8e475', description: 'One or more Work Order associated with this Service Request has been marked as "Incomplete" for a specified reason, usually requiring followup by the Office or another Technician.' },
    COMPLETE: { id: '4', name: 'Complete', color: '#a4dd00', description: 'All the Work Orders for this Service Request have been marked as "Complete" by the Technician.' },
    INVOICED: { id: '5', name: 'Invoiced', color: '#68bc00', description: 'The Customer has been invoiced for all Work Orders associated with this Service Request.' },
    CLOSED: { id: '6', name: 'Closed', color: '#333333', description: 'This Work Orders associated with this Service Request have been Completed, Invoiced, and Paid, or Incomplete and Closed due to Customer Request.  There are no pending actions.' },
};
export const ROLES = {
    DISPATCHER: { id: '1', name: 'Dispatcher', order: 1 },
    TECHNICIAN: { id: '2', name: 'Technician', order: 2 },
    LEAD: { id: '3', name: 'Lead', order: 3 },
    INVENTORY_MANAGER: { id: '4', name: 'Inventory Manager', order: 4 },
    ASSET_MANAGER: { id: '5', name: 'Asset Manager', order: 5 },
    ACCOUNTING: { id: '6', name: 'Accounting', order: 6 },
    ADMINISTRATOR: { id: '0', name: 'Administrator', order: 7 },
    OWNER: { id: '99', name: 'Owner', order: 8 },
};
export const SERVICE_REQUEST_TERMS = {
    SERVICE_CALL: { id: '1', name: 'Service Call', code: 'SC', color: '#cccccc', description: 'A Service Call is a Service Request and an associated Work Order that is Completed and Invoiced at the of the initial visit', change: 'Changing the terms to "Service Call" from here will delete the Quote and convert the Service Request to a Service Call.', possible: true },
    PROJECT_QUOTE: { id: '2', name: 'Project Quote', code: 'PR', color: 'steelblue', description: 'When an estimate is created, or when a Service Request expands beyond the scope of a single Service Call, a Project Quote is created and approved by the Customer.  This quote may be divided among several technicians over several time periods.' },
    TIME_MATERIAL: { id: '3', name: 'Time & Materials', code: 'TM', color: '#000000', description: 'When a project Quote cannot be determined due to various unknowns, the Customer may approve "Time & Materials" in which the Work Orders reflect actual time and materials spent on the job.' },
    CALLBACK: { id: '4', name: 'Callback', code: 'CB', color: 'red', description: 'A Callback is a Service Request and an associated Work Order that was previously completed and is showing signs of being defective or improperly serviced.  It is inticipated to be completed at no additional cost.' },
    SERVICE_RECORD: { id: '5', name: 'Service Record', code: 'SR', color: '#666666', description: 'A Service Record is service performed by an outside provider.' },
};
export const SCHEDULE_EVENT_TYPE = {
    RESERVED_TIMELINE: { id: '0', name: 'Reserved Timeline' },
    WORKORDER: { id: '1', name: 'Work Order' },
    REQUESTED_TIME_OFF: { id: '2', name: 'Requested Time Off' },
    APPROVED_TIME_OFF: { id: '3', name: 'Approved Time Off' },
    INTERNAL_WORKORDER: { id: '4', name: 'Internal Work Order' },
};
export const SERVICE_ITEMS = {
    LABOR: { id: 'labor', position: 1, name: 'Labor', type: 'Service' },
    SERVICE_ITEMS: { id: 'service', position: 2, name: 'Service Items', singular: 'Service Item', type: 'Inventory' },
    STOCK_PARTS: { id: 'stock', position: 3, name: 'Stock Parts', singular: 'Stock Part', type: 'Inventory' },
    NON_STOCK_PARTS: { id: 'non-stock', position: 4, name: 'Non-Stock Parts', singular: 'Non-Stock Part', type: 'Inventory' },
    TRAVEL: { id: 'travel', position: 5, name: 'Travel', type: 'NonInventory' },
    EXTERNAL: { id: 'external', position: 6, name: 'External', type: 'NonInventory' },
};
export const STATUS_TABLES = {
    REQUESTS: { name: 'Service Requests', singular: 'Service Request' },
    WORKORDERS: { name: 'Work Orders', singular: 'Work Order' },
    PURCHASE_ORDERS: { name: 'Purchase Orders', singular: 'Purchase Order' },
    INVOICES: { name: 'Invoices', singular: 'Invoice' },
    QUOTES: { name: 'Quotes', singular: 'Quote' },
};
export const UNITS = {
    EACH: { id: '0', name: 'each' },
    PAIR: { id: '1', name: 'pair' },
    POUND: { id: '2', name: 'lb' },
    OUNCE: { id: '3', name: 'oz' },
    GALLON: { id: '4', name: 'gallon' },
    QUART: { id: '5', name: 'quart' },
    CASE: { id: '6', name: 'case' },
    FOOT: { id: '7', name: 'foot' },
    INCH: { id: '8', name: 'inch' },
};
export const URGENCY = {
    LOW: { id: '0', name: 'Low', color: '#cccccc' },
    MEDIUM: { id: '1', name: 'Medium', color: 'green' },
    HIGH: { id: '2', name: 'High', color: '#ffbf00' },
    URGENT: { id: '3', name: 'Urgent', color: '#ff4000' },
};
export const WORKORDERS = {
    UNASSIGNED: { id: '0', name: 'Unassigned', color: '#b3b3b3', disabled: false, description: 'An "Unassigned" Work Order has been created but not assigned to a technician.  This can be done by editing the Work Order, or dragging it to Technician\'s Schedule from the Schedule view.', change: 'Changing the status to "Unassigned" will remove the current technician from the Work Order and allow another Technician to be assigned.', caution: true, possible: true },
    ASSIGNED: { id: '1', name: 'Assigned', color: '#16a5a5', disabled: true, description: '"Assigned" means that the Work Order has been assigned to a technician.', change: 'You cannot change the status to "Assigned" from here.  Please edit the Work Order and assign a Technician.', caution: false, possible: false },
    ENROUTE: { id: '2', name: 'Enroute', color: '#679cca', disabled: false, description: 'A Work Order marked as "Enroute" indicates that the Technician assigned to this Work Order is currently "Enroute" to the Service Request location.', change: 'You may set the status of this Work Order to indicate the Technician is "Enroute".', caution: false, possible: true },
    ONSITE: { id: '3', name: 'Onsite', color: '#937eca', disabled: false, description: 'A Work Order marked as "Onsite" indicates that the Technician assigned to this Work Order is currently "Onsite" at the Service Request location.', change: 'You may set the status of this Work Order to indicate the Technician is "Onsite".', caution: false, possible: true },
    INPROGRESS: { id: '4', name: 'In Progress', color: '#FF8C00', disabled: false, description: 'A Work Order marked as "In Progress" indicates that the Technician assigned to this Work Order has started, but is not yet completed with this Work Order.', change: 'You may set the status of this Work Order to indicate the Technician is "In Progress".', caution: false, possible: true },
    INCOMPLETE: { id: '5', name: 'Incomplete', color: '#e8e475', disabled: false, description: 'An "Incomplete" Work Order has been marked as "Incomplete" for a specified reason usually requiring followup by the Office or another Technician', updatable: true },
    COMPLETE: { id: '6', name: 'Complete', color: '#a4dd00', disabled: false, description: 'A Work Order marked as "Complete" has been updated with Line Items for materials and labor and is ready for Invoicing.', change: 'Changing the status to "Complete" enables the generation of an Invoice for this Work Order. Be sure all material and line items have been completed. ', caution: true, possible: true },
    INVOICED: { id: '7', name: 'Invoiced', color: '#68bc00', disabled: true, description: 'The Customer has been invoiced for this Work Order.', change: 'You cannot change the status to "Invoiced" from here.  Please generate an invoice from the Service Request Panel.', caution: false, possible: false },
    CLOSED: { id: '8', name: 'Closed', color: '#333333', disabled: false, description: 'A "Closed" work Order has been Completed, Invoiced, and Paid, or Incomplete and Closed due to Customer Request.  There are no pending actions.', change: 'Changing the status to "Closed" will remove the Work Order from active status and expect no further action.', caution: true, possible: true },
};
export const WORKORDERS_CHECKED_IN = [ WORKORDERS.ENROUTE.id, WORKORDERS.ONSITE.id, WORKORDERS.INPROGRESS.id ];

/* DEFAULTS ------------------------------------------------------------------------------------------ */

export const DEFAULT_SCHEDULE = {
    1: { end: '17:00', isWorkDay: false, start: '07:00' },
    2: { end: '17:00', isWorkDay: true, start: '07:00' },
    3: { end: '17:00', isWorkDay: true, start: '07:00' },
    4: { end: '17:00', isWorkDay: true, start: '07:00' },
    5: { end: '17:00', isWorkDay: true, start: '07:00' },
    6: { end: '17:00', isWorkDay: true, start: '07:00' },
    7: { end: '17:00', isWorkDay: false, start: '07:00' }
};
export const ADDITIONAL_FIELDS = {
    VEHICLES: {
        id: '0',
        name: 'Vehicles',
        fields: [
            { grid: 'col-4', label: 'Year', field: 'year', type: 'text' },
            { grid: 'col-4', label: 'Make', field: 'make', type: 'text' },
            { grid: 'col-4', label: 'Model', field: 'model', type: 'text' },
            { grid: 'col-6', label: 'VIN', field: 'vin', type: 'text' },
            { grid: 'col-6', label: 'Plate #', field: 'plate', type: 'text' },
            { grid: 'col-6', label: 'Engine', field: 'engine', type: 'text' },
            { grid: 'col-6', label: 'Miles', field: 'miles', type: 'text', append: 'mi' },
        ],
        options: [
            { text: 'Predefined Licenses / Docs', type: 'plaintext' },
            { name: 'Vehicle Title', field: 'option_vehicle_title', type: 'checkbox' },
            { name: 'Registration', field: 'option_registration', type: 'checkbox' },
            { name: 'Insurance', field: 'option_insurance', type: 'checkbox' },
            { name: 'Bill of Sale', field: 'option_bill_of_sale', type: 'checkbox' },
        ],
        columns: [
            { name: 'Year', field: 'year' },
            { name: 'Make', field: 'make' },
        ],
        search: [
            { name: 'VIN', field: 'vin', width: '0' },
            { name: 'Plate #', field: 'plate', width: '0' },
            { name: 'Title #', field: 'title', width: '0' },
        ]
    },
    EQUIPMENT: {
        id: '1',
        name: 'Equipment',
        fields: [
            { grid: 'col-6', label: 'Year', field: 'year', type: 'text' },
            { grid: 'col-6', label: 'Make', field: 'make', type: 'text' },
            { grid: 'col-6', label: 'Model', field: 'model', type: 'text' },
            { grid: 'col-6', label: 'Serial #', field: 'serial', type: 'text' },
        ],
        columns: [
            { name: 'Year', field: 'year' },
            { name: 'Make', field: 'make' },
            { name: 'Model', field: 'model' },
            { name: 'Serial #', field: 'serial' },
        ],
        search: []
    },
    PROPERTIES: {
        id: '2',
        name: 'Properties',
        fields: [
            { grid: 'col-6', label: 'Address', field: 'address', type: 'text' },
            { grid: 'col-6', label: 'City', field: 'city', type: 'text' },
            { grid: 'col-6', label: 'State', field: 'state', type: 'text' },
            { grid: 'col-6', label: 'ZIP', field: 'zip', type: 'text' },
        ],
        columns: [
            { name: "Address", field: "address" },
        ],
        search: [
            { name: "Address", field: "address", width: "0" },
        ]
    },
};

// PERMISSIONS -----------------------------------------------------------------------------------

export const PERMISSIONS_ORDER = { VIEW: 0, EDIT: 1, CREATE: 2, RESTORE: 3, DELETE: 4 };

export const PERMISSIONS = {
    ACTIVITY_LOGS_DELETE: { key: 'ACTIVITY_LOGS_DELETE', name: "Activity Logs - Delete", roles: ["99","0"] },
    ACTIVITY_LOGS_VIEW: { key: 'ACTIVITY_LOGS_VIEW', name: "Activity Logs - View", roles: ["99","0"] },
    ARCHIVES_DELETE: { key: 'ARCHIVES_DELETE', name: "Archives - Delete", roles: ["99"] },
    ARCHIVES_RESTORE: { key: 'ARCHIVES_RESTORE', name: "Archives - Restore", roles: ["99"] },
    ARCHIVES_VIEW: { key: 'ARCHIVES_VIEW', name: "Archives - View", roles: ["99"] },
    ASSETS_CREATE: { key: 'ASSETS_CREATE', name: "Assets - Create", roles: ["99","0","3","4"] },
    ASSETS_DELETE: { key: 'ASSETS_DELETE', name: "Assets - Delete", roles: ["99","0"] },
    ASSETS_EDIT: { key: 'ASSETS_EDIT', name: "Assets - Edit", roles: ["99","0","2","3","4"] },
    ASSETS_VIEW: { key: 'ASSETS_VIEW', name: "Assets - View", roles: ["0","1","99","2","3","4"] },
    ATTACHMENTS_CREATE: { key: 'ATTACHMENTS_CREATE', name: "Attachments - Create", roles: ["99","0","2","3"] },
    ATTACHMENTS_DELETE: { key: 'ATTACHMENTS_DELETE', name: "Attachments - Delete", roles: ["99","0","2","3"] },
    ATTACHMENT_VIEW: { key: 'ATTACHMENT_VIEW', name: "Attachments - View", roles: ["99","0","1","2","3","4"] },
    CALL_CENTER: { key: 'CALL_CENTER', name: "Call Center", roles: ["1","99","0"] },
    CUSTOMERS_CREATE: { key: 'CUSTOMERS_CREATE', name: "Customers - Create", roles: ["99","0","1","3","4"] },
    CUSTOMERS_DELETE: { key: 'CUSTOMERS_DELETE', name: "Customers - Delete", roles: ["99","0"] },
    CUSTOMERS_EDIT: { key: 'CUSTOMERS_EDIT', name: "Customers - Edit", roles: ["1","99","0","2","3","4"] },
    CUSTOMERS_VIEW: { key: 'CUSTOMERS_VIEW', name: "Customers - View", roles: ["0","1","99","2","3","4"] },
    DASHBOARD_OVERVIEW: { key: 'DASHBOARD_OVERVIEW', name: "Dashboard - Financial Overview", roles: ["99"] },
    DASHBOARD_PROJECTS: { key: 'DASHBOARD_PROJECTS', name: "Dashboard - Projects", roles: ["99","0","3"] },
    DASHBOARD_SERVICE_MAP: { key: 'DASHBOARD_SERVICE_MAP', name: "Dashboard - Field Service Map", roles: ["99","0","3"] },
    EMPLOYEES_CREATE: { key: 'EMPLOYEES_CREATE', name: "Employees - Create", roles: ["99","0"] },
    EMPLOYEES_DELETE: { key: 'EMPLOYEES_DELETE', name: "Employees - Delete", roles: ["99","0"] },
    EMPLOYEES_EDIT: { key: 'EMPLOYEES_EDIT', name: "Employees - Edit", roles: ["99","0"] },
    EMPLOYEES_VIEW: { key: 'EMPLOYEES_VIEW', name: "Employees - View", roles: ["0","99"] },
    INVENTORY_CATEGORIES: { key: 'INVENTORY_CATEGORIES', name: "Stock Parts - New Categories", roles: ["99","0","2","3"] },
    INVENTORY_CREATE: { key: 'INVENTORY_CREATE', name: "Inventory - Create", roles: ["2","99","0","3"] },
    INVENTORY_DELETE: { key: 'INVENTORY_DELETE', name: "Inventory - Delete", roles: ["99","0"] },
    INVENTORY_EDIT: { key: 'INVENTORY_EDIT', name: "Inventory - Edit", roles: ["2","99","0","3"] },
    INVENTORY_VIEW: { key: 'INVENTORY_VIEW', name: "Inventory - View", roles: ["99","2","0","1","3","4"] },
    INVOICES_EDIT: { key: 'INVOICES_EDIT', name: "Invoices - Create/Edit", roles: ["99","0","4","2","3"] },
    INVOICES_VIEW: { key: 'INVOICES_VIEW', name: "Invoices - View", roles: ["99","0","4","2","3"] },
    MESSAGING: { key: 'MESSAGING', name: "Messaging", roles: ["99","0","1","2","3","4"] },
    PAYMENTS_DELETE: { key: 'PAYMENTS_DELETE', name: "Payments - Delete", roles: ["99","0","4"] },
    PAYMENTS_EDIT: { key: 'PAYMENTS_EDIT', name: "Payments - Edit", roles: ["99","0","4"] },
    PAYMENTS_RECEIVE: { key: 'PAYMENTS_RECEIVE', name: "Payments - Receive", roles: ["99","0","4","2","3","1"] },
    PAYMENTS_VIEW: { key: 'PAYMENTS_VIEW', name: "Payments - View", roles: ["2","99","0","4","3","1"] },
    PURCHASE_ORDERS_DELETE: { key: 'PURCHASE_ORDERS_DELETE', name: "Purchase Orders - Delete", roles: ["99","0","4"] },
    PURCHASE_ORDERS_EDIT: { key: 'PURCHASE_ORDERS_EDIT', name: "Purchase Orders - Edit", roles: ["99","0","4"] },
    PURCHASE_ORDERS_RECEIVE: { key: 'PURCHASE_ORDERS_RECEIVE', name: "Purchase Orders - Receive", roles: ["99","0","4","2","3","1"] },
    PURCHASE_ORDERS_VIEW: { key: 'PURCHASE_ORDERS_VIEW', name: "Purchase Orders - View", roles: ["2","99","0","4","3","1"] },
    QUOTES_CREATE: { key: 'QUOTES_CREATE', name: "Quotes - Create", roles: ["99","0","3"] },
    QUOTES_DELETE: { key: 'QUOTES_DELETE', name: "Quotes - Delete", roles: ["99","0","3"] },
    QUOTES_EDIT: { key: 'QUOTES_EDIT', name: "Quotes - Edit", roles: ["99","0","3"] },
    QUOTES_VIEW: { key: 'QUOTES_VIEW', name: "Quotes - View", roles: ["99","0","1","2","3","4"], },
    SCHEDULE_EDIT: { key: 'SCHEDULE_EDIT', name: "Schedule - Edit", roles: ["2","99","0","1","3"] },
    SCHEDULE_PERSONAL_TIME_APPROVE: { key: 'SCHEDULE_PERSONAL_TIME_APPROVE', name: "Schedule - Approve/Deny Personal Time", roles: ["99","0"] },
    SCHEDULE_VIEW: { key: 'SCHEDULE_VIEW', name: "Schedule - View", roles: ["99","2","0","1","3","4"] },
    SERVICE_REQUESTS_CREATE: { key: 'SERVICE_REQUESTS_CREATE', name: "Service Requests - Create", roles: ["2","99","0","1"] },
    SERVICE_REQUESTS_DELETE: { key: 'SERVICE_REQUESTS_DELETE', name: "Service Requests - Delete", roles: ["99","0"] },
    SERVICE_REQUESTS_EDIT: { key: 'SERVICE_REQUESTS_EDIT', name: "Service Requests - Edit", roles: ["99","2","0","1","3"] },
    SERVICE_REQUESTS_TERMS: { key: 'SERVICE_REQUESTS_TERMS', name: "Service Requests - Change Terms", roles: ["99","0","3","2"] },
    SERVICE_REQUESTS_VIEW: { key: 'SERVICE_REQUESTS_VIEW', name: "Service Requests - View", roles: ["99","2","0","1","3","4"] },
    SETTINGS_ACCOUNTING: { key: 'SETTINGS_ACCOUNTING', name: "Settings - Accounting", roles: ["99","0", "5"] },
    SETTINGS_ASSETS: { key: 'SETTINGS_ASSETS', name: "Settings - Assets", roles: ["99"] },
    SETTINGS_COMPANY: { key: 'SETTINGS_COMPANY', name: "Settings - Company", roles: ["99"] },
    SETTINGS_DEPARTMENTS: { key: 'SETTINGS_DEPARTMENTS', name: "Settings - Departments", roles: ["99"] },
    SETTINGS_IMPORT_EXPORT: { key: 'SETTINGS_IMPORT_EXPORT', name: "Settings - Import / Export", roles: ["99"] },
    SETTINGS_INVENTORY: { key: 'SETTINGS_INVENTORY', name: "Settings - Inventory", roles: ["99", "0", "4"] },
    SETTINGS_QUOTES: { key: 'SETTINGS_QUOTES', name: "Settings - Quotes", roles: ["99","0", "5"] },
    SETTINGS_INVOICES: { key: 'SETTINGS_INVOICES', name: "Settings - Invoices/Quotes", roles: ["99","0", "5"] },
    SETTINGS_JOB_CATEGORIES: { key: 'SETTINGS_JOB_CATEGORIES', name: "Settings - Job Categories", roles: ["99"] },
    SETTINGS_QUICKBOOKS: { key: 'SETTINGS_QUICKBOOKS', name: "Settings - QuickBooks", roles: ["99","0", "5"] },
    SETTINGS_SERVICE: { key: 'SETTINGS_SERVICE', name: "Settings - Service", roles: ["99", "0"] },
    SETTINGS_TIME_CLOCK: { key: 'SETTINGS_TIME_CLOCK', name: "Settings - Time Clock", roles: ["99", "0"] },
    SETTINGS_SERVICE_ITEMS: { key: 'SETTINGS_SERVICE_ITEMS', name: "Settings - Service Items", roles: ["2","99","0","3"] },
    SETTINGS_TAXES: { key: 'SETTINGS_TAXES', name: "Settings - Taxes", roles: ["99", "0", "5"] },
    TIMECLOCK_EDIT: { key: 'TIMECLOCK_EDIT', name: "Timeclock - Edit", roles: ["99"] },
    TIMECLOCK_VIEW: { key: 'TIMECLOCK_VIEW', name: "Timeclock - View", roles: ["99","2","0","1","3","4"] },
    VENDORS_CREATE: { key: 'VENDORS_CREATE', name: "Vendors - Create", roles: ["2","99","0","3"] },
    VENDORS_DELETE: { key: 'VENDORS_DELETE', name: "Vendors - Delete", roles: ["99","0"] },
    VENDORS_EDIT: { key: 'VENDORS_EDIT', name: "Vendors - Edit", roles: ["2","99","0","3"] },
    VENDORS_VIEW: { key: 'VENDORS_VIEW', name: "Vendors - View", roles: ["99","2","0","1","3","4"] },
    WORK_ORDERS_CREATE: { key: 'WORK_ORDERS_CREATE', name: "Work Orders - Create", roles: ["99","0","1","2","3"] },
    WORK_ORDERS_DELETE: { key: 'WORK_ORDERS_DELETE', name: "Work Orders - Delete", roles: ["2","99","0"] },
    WORK_ORDERS_EDIT: { key: 'WORK_ORDERS_EDIT', name: "Work Orders - Edit", roles: ["2","99","0","1","3"] },
    WORK_ORDERS_VIEW: { key: 'WORK_ORDERS_VIEW', name: "Work Orders - View", roles: ["99","0","1","4","2","3"] },
}

// ACTIVITY LOGS -----------------------------------------------------------------------------------

export const LOG = {
    ADDRESS: { key: 'ADDRESS', name: 'Address' },
    ASSET: { key: 'ASSET', name: 'Asset' },
    ASSET_TYPE: { key: 'ASSET_TYPE', name: 'Asset Type' },
    ASSET_CATEGORY: { key: 'ASSET_CATEGORY', name: 'Asset Category' },
    CALL_LOG: { key: 'CALL_LOG', name: 'Call Log' },
    CONTACT: { key: 'CONTACT', name: 'Contact' },
    COMPANY: { key: 'COMPANY', name: 'Company Settings' },
    CUSTOMER: { key: 'CUSTOMER', name: 'Customer' },
    DEPARTMENT: { key: 'DEPARTMENT', name: 'Department' },
    EMPLOYEE: { key: 'EMPLOYEE', name: 'Employee' },
    IMPORT_EXPORT: { key: 'IMPORT_EXPORT', name: 'Import/Export' },
    MESSAGE: { key: 'MESSAGE', name: 'Message' },
    PARTS_CATEGORY: { key: 'PARTS_CATEGORY', name: 'Parts Category' },
    PURCHASE_ORDER: { key: 'PURCHASE_ORDER', name: 'Purchase Order' },
    QUOTE: { key: 'QUOTE', name: 'Quote' },
    SCHEDULE: { key: 'SCHEDULE', name: 'Schedule' },
    SERVICE_REQUEST: { key: 'SERVICE_REQUEST', name: 'Service Request' },
    SETTINGS: { key: 'SETTINGS', name: 'Settings' },
    INVENTORY: { key: 'INVENTORY', name: 'Inventory' },
    USER: { key: 'USER', name: 'User' },
    VENDOR: { key: 'VENDOR', name: 'Vendor' },
    WORK_ORDER: { key: 'WORK_ORDER', name: 'Work Order' },
    WORK_ORDER_SCHEDULE: { key: 'WORK_ORDER_SCHEDULE', name: 'Work Order Schedule' },
};

export const ACTION = {
    VIEWED: { key: 'VIEWED', name: 'Viewed' },
    CREATED: { key: 'CREATED', name: 'Created' },
    MODIFIED: { key: 'MODIFIED', name: 'Modified' },
    APPROVED: { key: 'APPROVED', name: 'Approved' },
    REJECTED: { key: 'REJECTED', name: 'Rejected' },
    DELETED: { key: 'DELETED', name: 'Deleted' },
    ARCHIVED: { key: 'ARCHIVED', name: 'Archived' },
    RESTORED: { key: 'RESTORED', name: 'Restored' },
    GENERATED: { key: 'GENERATED', name: 'Generated' },
    PRINTED: { key: 'PRINTED', name: 'Printed' },
    EMAILED: { key: 'EMAILED', name: 'Emailed' },
    MISC: { key: 'MISC', name: 'Misc' },
}

// -------------------------------------------------------------------------------------------
// FLUTTER MECH ARMOR -----------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

/* --------------------------------------------------------------------------/*
    title - to be displayed in App Status bar
    icon - to be displayed on tab
    link - 'id' of link in Navbar to be triggered
    path - start of path(s) to match this setting (split on '|')
    permissions: permission setting required for access
    submenu - creation of subtabs - one entire entry required for each tab
/* --------------------------------------------------------------------------*/

/* ASSET MANAGER ------------------------------------------------------------------------------------------*/

export const ASSETS_TABS = [
    { title: 'Dashboard', icon: 'dashboard', link: 'link-dashboard', path: 'dashboard' },
    { title: 'Assets', icon: 'assets', link: 'link-assets', path: 'assets' },
    { title: 'Inventory', icon: 'inventory', link: 'link-inventory', path: 'inventory' },
    { title: 'Vendors', icon: 'warehouse', link: 'link-vendors', path: 'vendors' },
];    
export const ASSETS_MORE = [
    { title: 'Customers', icon: 'people', link: 'link-customers', path: 'customers' },
    { title: 'Employees', icon: 'badge', link: 'link-employees', permissions: 'EMPLOYEES_VIEW' },
]    

/* SERVICE MANAGER ----------------------------------------------------------------------------------------*/

export const SERVICE_TABS = [
    { title: 'Dashboard', icon: 'dashboard', link: 'link-dashboard', path: 'dashboard' },
    { title: 'Customers', icon: 'people', link: 'link-customers', path: 'customers' },
    { title: 'Inventory', icon: 'inventory', link: 'link-inventory', path: 'inventory' },
    { title: 'Vendors', icon: 'warehouse', link: 'link-vendors', path: 'vendors' },
];    
export const SERVICE_MORE = [
    { title: 'Employees', icon: 'badge', link: 'link-employees', permissions: 'EMPLOYEES_VIEW' },
]    

/* ALL APPS ----------------------------------------------------------------------------------------------*/

export const SETTINGS_NAV = [
    { title: 'Company Info', icon: 'info', link: 'link-settings-company', permissions: 'SETTINGS_COMPANY', group: 'Company' },
    { title: 'Departments', icon: 'settings', link: 'link-settings-departments', permissions: 'SETTINGS_COMPANY' },
    { title: 'Time Clock', icon: 'schedule', link: 'link-settings-timeclock', permissions: 'SETTINGS_COMPANY' },
    { title: 'Assets', icon: 'checklist', link: 'link-settings-assets', permissions: 'SETTINGS_ASSETS', group: 'Preferences / Defaults' },
    { title: 'Inventory', icon: 'home_repair_service', link: 'link-settings-inventory', permissions: 'SETTINGS_INVENTORY' },
    { title: 'Service', icon: 'event_note', link: 'link-settings-service', permissions: 'SETTINGS_SERVICE' },
    { title: 'Quotes', icon: 'event_note', link: 'link-settings-quotes', permissions: 'SETTINGS_QUOTES', group: 'Accounting' },
    { title: 'Invoices', icon: 'event_note', link: 'link-settings-invoices', permissions: 'SETTINGS_INVOICES' },
    { title: 'Taxes', icon: 'event_note', link: 'link-settings-taxes', permissions: 'SETTINGS_INVOICES' },
]

export const MAIN_NAV = [
    { title: 'Login', path: 'login' },
    { title: 'Register', path: 'register_company' },
    { title: 'Delete Account', path: 'account_delete' },

    /* Dashboard ----------------------------------------------------------------------*/
    { title: 'Dashboard', path: 'dashboard', root: true, backlink: false },

    { title: 'Dashboard', path: 'my-workorders', root: false, backlink: true },
    { title: 'Service Request', path: 'my-workorders/*', root: false, backlink: true },
    { title: 'Work Order', path: 'my-workorders/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'unassigned-sr', root: false, backlink: true },
    { title: 'Service Request', path: 'unassigned-sr/*', root: false, backlink: true },
    { title: 'Work Order', path: 'unassigned-sr/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'inprogress-sr', root: false, backlink: true },
    { title: 'Service Request', path: 'inprogress-sr/*', root: false, backlink: true },
    { title: 'Work Order', path: 'inprogress-sr/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'completed-sr', root: false, backlink: true },
    { title: 'Service Request', path: 'completed-sr/*', root: false, backlink: true },
    { title: 'Work Order', path: 'completed-sr/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'invoiced-sr', root: false, backlink: true },
    { title: 'Service Request', path: 'invoiced-sr/*', root: false, backlink: true },
    { title: 'Work Order', path: 'invoiced-sr/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'unassigned-wo', root: false, backlink: true },
    { title: 'Service Request', path: 'unassigned-wo/*', root: false, backlink: true },
    { title: 'Work Order', path: 'unassigned-wo/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'inprogress-wo', root: false, backlink: true },
    { title: 'Service Request', path: 'inprogress-wo/*', root: false, backlink: true },
    { title: 'Work Order', path: 'inprogress-wo/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'incomplete-wo', root: false, backlink: true },
    { title: 'Service Request', path: 'incomplete-wo/*', root: false, backlink: true },
    { title: 'Work Order', path: 'incomplete-wo/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'quote-drafts', root: false, backlink: true },
    { title: 'Service Request', path: 'quote-drafts/*', root: false, backlink: true },
    { title: 'Work Order', path: 'quote-drafts/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'quote-pending', root: false, backlink: true },
    { title: 'Service Request', path: 'quote-pending/*', root: false, backlink: true },
    { title: 'Work Order', path: 'quote-pending/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'quote-declined', root: false, backlink: true },
    { title: 'Service Request', path: 'quote-declined/*', root: false, backlink: true },
    { title: 'Work Order', path: 'quote-declined/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    { title: 'Dashboard', path: 'quote-accepted', root: false, backlink: true },
    { title: 'Service Request', path: 'quote-accepted/*', root: false, backlink: true },
    { title: 'Work Order', path: 'quote-accepted/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    /* Assets -------------------------------------------------------------------------*/
    { title: 'Assets', path: 'assets', root: true, backlink: false },
    { title: 'Assets', path: 'assets/*', root: false, backlink: true },
    { title: 'Service Request', path: 'assets/*/*/*/service_requests/*', root: false, backlink: true },
    { title: 'Work Order', path: 'assets/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    /* Customers ----------------------------------------------------------------------*/
    { title: 'Customers', path: 'customers', root: true, backlink: false },
    { title: 'Customer', path: 'customers/*', root: false, backlink: true },
    { title: 'Service Request', path: 'customers/*/*/*/service_requests/*', root: false, backlink: true },
    { title: 'Work Order', path: 'customers/*/*/*/service_requests/*/work_orders/*', root: false, backlink: true },

    /* Employees ----------------------------------------------------------------------*/
    { title: 'Employees', path: 'employees', root: true, backlink: false },
    { title: 'Employees', path: 'employees/*', root: false, backlink: true },
      
    /* Inventory ----------------------------------------------------------------------*/
    { title: 'Inventory', path: 'inventory', root: true, backlink: false },
    { title: 'Inventory', path: 'inventory/*', root: false, backlink: true },
    
    /* Vendors ------------------------------------------------------------------------*/
    { title: 'Vendors', path: 'vendors', root: true, backlink: false },
    { title: 'Vendors', path: 'vendors/*', root: false, backlink: true },
    
    /* Settings -----------------------------------------------------------------------*/
    { title: 'More Options', path: 'settings', root: true, moreOptions: true, backlink: false },
    { title: 'Company Info', path: 'settings/company', root: false, moreOptions: true, backlink: true },
    { title: 'Departments', path: 'settings/departments', root: false, moreOptions: true, backlink: true },
    { title: 'Time Clock', path: 'settings/timeclock', root: false, moreOptions: true, backlink: true },
    { title: 'Assets', path: 'settings/assets', root: false, moreOptions: true, backlink: true },
    { title: 'Inventory', path: 'settings/inventory', root: false, moreOptions: true, backlink: true },
    { title: 'Service', path: 'settings/service', root: false, moreOptions: true, backlink: true },
    { title: 'Quotes', path: 'settings/quotes', root: false, moreOptions: true, backlink: true },
    { title: 'Invoices', path: 'settings/invoices', root: false, moreOptions: true, backlink: true },
    { title: 'Taxes', path: 'settings/taxes', root: false, moreOptions: true, backlink: true },
]

export const ACCOUNTS = [
    { name: 'Testing', handle: 'testing' },
    { name: 'Hukills, FTW', handle: 'hukillsftw' },
    { name: 'BioSource', handle: 'biosource' },
    { name: 'C4Repair', handle: 'c4repair' },
];
