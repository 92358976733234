import * as actions_quotes from 'actions/quotes-actions';
import * as actions_users from 'actions/users-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import LineItemsBuilder from 'components/line-items/line-items-builder';
import React, { useEffect, useState } from 'react';
import SelectOptions from './select-options';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { APPS, CUSTOMERS, SERVICE_ITEMS } from 'components/constants';
import { Ibox, ModalAlert, Select, Spinner, Table } from 'enspire-manager-framework';
import { QUOTES, WORKORDERS } from 'components/constants';
import { confirmChangeStatus } from 'components/common/toolbox';
import { confirmDelete } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { columnWidths } from 'components/common/toolbox';

const LineItemsTab = (props) => {
    
    /* Hooks --------------------------*/
    
    const seg = 5;
    const params = useParams();
	const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();
    
    const profile = useSelector((store) => store.profiles.profile);
    const quote = useSelector((store) => store.quotes.quote);
    const settings = useSelector(store => store.settings.settings);
    const user = useSelector((store) => store.users.user);
    const user_permissions = useSelector((store) => store.users.user_permissions);
    const workOrderServiceItems = useSelector((store) => store.workOrders.work_order_items);
    const workOrders = useSelector((store) => store.workOrders);
    const workOrder = useSelector((store) => store.workOrders.work_order);

    const [notified, setNotified] = useState(false);
    const [selectedOption, setSelectedOption] = useState(0);

    const isQuote = (props.source == 'quotes');
    const isWorkOrder = (props.source == 'work-orders');

    const serviceItems = (isWorkOrder) ? workOrderServiceItems : quote?.serviceItems ?? [];
    const filteredServiceItems = _.filter(serviceItems, (o) => o.type != 'external');
    const entity = (isWorkOrder) ? workOrder : quote;
    const entityName = (isWorkOrder) ? 'Work Order' : 'Quote';
    const isCompleted = (isWorkOrder)
        ?   (parseInt(entity?.statusId) >= parseInt(WORKORDERS.COMPLETE.id))
        :   (parseInt(entity?.statusId) >= parseInt(QUOTES.ACCEPTED.id));


    useEffect(() => {
        setSelectedOption((quote?.approvedOption) ? quote?.approvedOption : 0); 
    }, [quote?.approvedOption]);
    
    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/
    
    const handleEdit = (field, updatedItem, newValue) => {
        let newLineItems = filteredServiceItems.map((item, index) => {
            if (item.type != 'external') {
                if (
                    item.id == updatedItem.id &&
                    item.option == updatedItem.option &&
                    ((item.type != 'labor' && item.type != 'travel') || item.description == updatedItem.description)
                ) {
                    let count = (field != 'count') ? item.count : newValue;
                    let price = (field != 'price') ? item.price : newValue;
                    return { ...item, [field]: newValue, calculatedPrice: price * count };
                } else {
                    return item;
                }
            }
        });
        saveUpdates(newLineItems);
    };
    const handleCheckbox = (updatedItem, e) => {
        let newLineItems = filteredServiceItems.map((item, index) => {
            if (item.type != 'external') {
                if (item.id == updatedItem.id && ((item.type != 'labor' && item.type != 'travel') || item.description == updatedItem.description)) {
                    let taxable = !item.taxable;
                    return { ...item, taxable };
                } else {
                    return item;
                }
            }
        });
        saveUpdates(newLineItems);
    }
    const handleUndoComplete = () => {
        if (isWorkOrder) {
            confirmChangeStatus('Work Order', WORKORDERS.ASSIGNED.name, () => {
                dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { statusId: WORKORDERS.ASSIGNED.id, reason: '' }));
            });
        }
    }

    /* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const addItems = (items) => {
        
        let exists = false;
        let newLineItems = Object.assign([], filteredServiceItems);

        items.forEach((item) => {
            let count = item.count;
            delete newLineItems.count;
            
            newLineItems.forEach((lineItem, index) => {
                if (
                    lineItem.id == item.id && 
                    lineItem.type == item.type &&
                    lineItem.description == item.description &&
                    lineItem.option == selectedOption
                ) {
                    newLineItems[index] = { ...lineItem, count: parseFloat(newLineItems[index].count) + parseFloat(count) };
                    exists = true;
                }
            });
    
            let price = (item.type == 'labor' || item.type == 'travel') 
                ? item?.price ?? 0 
                : (item?.priceOverride) 
                    ? item.priceOverride
                    : (item?.averageCost) ? item.averageCost : 0;
    
            let taxable = (profile?.customerTypeId == CUSTOMERS.RESIDENTIAL.id)
                ?   (item.type == 'labor' || item.type == 'travel') 
                    ? settings?.invoices?.taxPrefs?.laborRes ?? false 
                    : settings?.invoices?.taxPrefs?.materialsRes ?? false
                :   (item.type == 'labor' || item.type == 'travel') 
                    ? settings?.invoices?.taxPrefs?.laborCom ?? false 
                    : settings?.invoices?.taxPrefs?.materialsCom ?? false;
    
            if (!exists) {
                let newItem = {
                    id: item.id,
                    option: selectedOption,
                    type: item.type,
                    name: item.name,
                    description: item.description ?? '',
                    count: count,
                    price: price,
                    calculatedPrice: count * price,
                    taxable: taxable,
                };
                newLineItems.push(newItem);
            }
        });

        saveUpdates(newLineItems);
        history.goBack();

        // if (!notified && !user.dismissed?.addItemsNotice) {
        //     ModalAlert({
        //         title: 'Line Items Added',
        //         input: "checkbox",
        //         validator: (isDismissed) => {
        //             if (isDismissed) dispatch(actions_users.dismissNotice(params.handle, user.email, 'addItemsNotice'));
        //         },
        //         label: `Do not show again`,
        //         html: `In order preserve your search results while adding items, this window will not close until you are Finished`,
        //         icon: 'info',
        //         confirm_color: '#8FBC8B',
        //         confirm_text: 'Okay',
        //         show_cancel: false,
        //         callback_success: () => {},
        //     });
        // }
        // setNotified(true);
        
        // const currentUser = firebase.auth().currentUser;
        // if (item.type == SERVICE_ITEMS.NON_STOCK_PARTS.id) dispatch(actions_users.addItemToRecent(params.handle, item, currentUser.email));
    }
    const removeItem = (deletedItem) => {
        let newLineItems = [];
        filteredServiceItems.forEach((item, index) => {
            if (!(
                item.id == deletedItem.id &&
                item.type == deletedItem.type &&
                item.description == deletedItem.description &&
                item.count == deletedItem.count &&
                item.taxable == deletedItem.taxable &&
                item.price == deletedItem.price &&
                item.option == selectedOption
            )) {
                newLineItems.push(item);
            }
        });
        saveUpdates(newLineItems);
    };

    /* Actions ----------------------------*/

    const setTaxRate = (e) => {
        if (isWorkOrder) {
            dispatch(actions_work_orders.updateWorkOrder(params.handle, params.work_order_id, { taxRateId: e.target.value}));
        } else if (props.source == 'quotes') {
            dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { taxRateId: e.target.value}));
        };
    }
    const saveUpdates = (newLineItems) => {
        if (isWorkOrder) {
            dispatch(actions_work_orders.updateWorkOrderServiceItems(params.handle, params.work_order_id, newLineItems));
        } else if (props.source == 'quotes') {
            let update = { ...quote, serviceItems: newLineItems };
            dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, update));
        }  
    }
    const openBuilder = () => {
        let location = toolbox.modifyPath(routeLocation?.pathname, seg, 'line-items');
        history.push({ pathname: location });
    }
    const createNewOption = () => {
        ModalAlert({
            title: 'Are you sure?',
            html: `Are you sure you want to create a new Option based on the current selected Option?`,
            icon: 'question',
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes',
            show_cancel: true,
            callback_success: () => {

                // Use highest option number to increment next option number
                var highestOption = 0;
                quote.options?.forEach((option) => {
                    if (option.index > highestOption) highestOption = option.index;
                });
                const newOptionNumber = parseInt(highestOption) + 1;
        
                // Copy all service items from currently selected option
                var serviceItems = [ ...quote?.serviceItems ];
                var newServiceItems = quote?.serviceItems?.filter((item) => item.option == selectedOption);
                newServiceItems = newServiceItems?.map((item) => ({ ...item, option: newOptionNumber }));
                serviceItems = [...serviceItems, ...newServiceItems];
        
                dispatch(actions_quotes.updateQuote(params.handle, params.quote_id, { options: [ ...quote.options, { 
                    name:`Option #${parseInt(newOptionNumber) + 1}`, 
                    index: newOptionNumber
                }], serviceItems }));

                setSelectedOption(newOptionNumber);
            }
        });
    };

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    const taxRate = settings?.invoices?.tax_rates?.[entity?.taxRateId]?.rate ?? 0;
    const optionItems = _.filter(serviceItems, (item) => item.option == selectedOption);

    const data = optionItems.map((item) => {
        let details = { ...item }
        let type = _.find(Object.values(SERVICE_ITEMS), { id: item.type });
        if (type) details = { ...details, _position: type.position.toString(), _display: type.name }
        details._noEdit = isCompleted;
        return details
    });

    if (entity) {
        /*------------------------------------
                TOTALS MATH
        -------------------------------------*/
        var sub_total = 0;
        var tax_total = 0;
        if (optionItems) {
            optionItems.map((item => { if (item.taxable) tax_total += (item.price * item.count) * taxRate/100; }));
            optionItems.map(item => { sub_total += (item.price * item.count); });
        }

        var grand_total = (tax_total + sub_total);

        var totals = {
            sub_total: sub_total.toFixed(2),
            tax_total: tax_total.toFixed(2),
            grand_total: (tax_total + sub_total).toFixed(2),
            balance: grand_total.toFixed(2)
        };
    }

    const columns = [
        { name: 'Name', field: 'name', width: columnWidths([40, 60]) },
        { name: 'Qty', field: 'count', text_align: 'center', width: columnWidths([20, 10]), edit: { type: 'text', callback: handleEdit }},
        { name: 'Price', field: 'price', type: 'number', format: 'usd', text_align: 'right', width: columnWidths([20, 15]), edit: { type: 'usd', callback: handleEdit }},
        { name: 'Total', field: 'calculatedPrice', type: 'number', format: 'usd', text_align: 'right', width: columnWidths([20, 15]) },
        // { name: 'Tax', field: 'taxable', type: 'checkbox', format: 'usd', callback: handleCheckbox, width: 10},
        { name: 'Description', field: 'description', edit: { type: 'textarea', placeholder: '(description)', callback: handleEdit } }, // 2nd Line
    ];

    return (
        <>
            { entity
                ? <>
                    <Ibox className="" show_spinner={workOrders.work_order_items_pending} no_fade={false} title={
                        <div>Service Items
                            <span className="float-right mt-n1">
                                { !isCompleted && isQuote &&
                                    <button className="btn btn-default btn-sm mr-3" onClick={createNewOption}>+ Option</button>
                                }
                                <button className="btn btn-primary btn-sm" disabled={isCompleted} onClick={openBuilder}>+ Line Items</button>
                            </span>
                        </div>
                    }>
                        { isCompleted &&
                            <div className="alert alert-info d-flex align-items-center">
                                <i class="fa-solid fa-lock fa-xl mr-3"></i>
                                <span>{`${entityName} Complete!`}</span>
                                { isWorkOrder &&
                                    <button className="btn btn-info btn-sm float-right" style={{ marginLeft: 'auto' }} onClick={ handleUndoComplete }>Undo Complete</button>
                                }
                            </div>

                        }

                        { !isCompleted && isQuote &&
                            <SelectOptions quote={ quote } selectedOption={ selectedOption } setSelectedOption={ setSelectedOption } />
                        }

                        <Table id="line-items-tab"
                            active_field={'id'}
                            columns={columns}
                            data={data}
                            delete={user_permissions?.WORK_ORDERS_EDIT && !isCompleted}
                            group_by={{ fields: ['_position'], direction: ['asc'], display: ['_display'] }}
                            highlight_search={true}
                            on_delete={(item) => confirmDelete('Line Item', removeItem.bind(this, item), { soft: true })}
                            on_focus={(target) => { props.setFocused(target) }}
                            second_line={'description'}
                            show_search={false}
                        >
                        </Table>

                        <div className="row">
                            <div className='col-6 justify-content-start align-items-end'>
                                <Select
                                    label={'Tax Rate:'}
                                    name={'taxRateId'}
                                    onChange={setTaxRate}
                                    value={entity?.taxRateId}
                                >
                                    <option value="">None</option>
                                    {settings?.invoices?.tax_rates?.map((rate, index) => {
                                        return <option key={rate.name} value={rate.rateId ?? index}>{rate?.name} - {rate.rate}%</option>
                                    })}
                                </Select>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <div className="p-1">
                                    <table className="subtotal">
                                        <tbody>
                                            <tr>
                                                <td align="right">SUBTOTAL: </td>
                                                <td width="5"></td>
                                                <td align="right">{toolbox.formatNumber(totals.sub_total, 2, true)}</td>
                                            </tr>
                                            <tr>
                                                <td align="right">TAX ({(taxRate ?? 0)}%):</td>
                                                <td width="5"></td>
                                                <td align="right">{toolbox.formatNumber(totals.tax_total, 2, true)}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td width="5"></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td align="right" className="font-weight-bold">GRAND TOTAL: </td>
                                                <td width="5"></td>
                                                <td align="right">{ toolbox.formatNumber(totals.grand_total, 2, true) }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Ibox>
                </>
                : <Spinner center />
            }

            {/* Integrate Routes under this component -------------------------------------------------- */}

            { params.form == 'line-items' &&
                <LineItemsBuilder addItems={ addItems } lineItems={ data }source={props.source} />
            }
        </>
    );
};

export default LineItemsTab;