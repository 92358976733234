import * as actions_invoices from 'actions/invoices-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useState } from 'react';
import { APPS } from 'components/constants';
import { ModalForm, Table } from 'enspire-manager-framework';
import { WORKORDERS } from 'components/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const moment = require('moment');
const _ = require('lodash');

const GenerateInvoiceForm = (props) => {
	
	/* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const params = useParams();
	const dispatch = useDispatch();
	const routeLocation = useLocation();
    const seg = (params.appId == APPS.SERVICE.id) ? 5 : 7;

	const workOrders = useSelector((store) => store.workOrders.work_orders);
	const employees = useSelector((store) => store.employees.employees);
	const invoices = useSelector((store) => store.invoices);

	const [state, setState] = useState({ work_orders: [] });

	/* Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

	const handleSelect = (item, e) => {
		let newWorkOrders = (state.work_orders) ? [...state.work_orders] : [];

		if (e?.target?.checked) newWorkOrders.push(item);
		else {
			let index = newWorkOrders.findIndex((wo) => wo.id == item.id);
			if (index > -1) { newWorkOrders.splice(index, 1); }
		}
		setState(prev => ({ ...prev, work_orders: newWorkOrders }));
	};

	/* Actions ------------------------------------------------------------------------------------------------------------------------------------*/
	
	const submitForm = () => {
		if (state.work_orders.length < 1) {
			return window.toastr.error('Please select at least one work order to include on the invoice.', "No Work Orders Selected");
		} else {
			dispatch(actions_invoices.createInvoice(params.handle, params.appId, params.request_id, state.work_orders, (invoiceId) => {
				let location = toolbox.modifyPath(routeLocation.pathname, seg, `form/0/service_requests/${params.request_id}/invoices/preview/${invoiceId}/payments`);
				props.history.replace({ pathname: location });
			}));
		}
	};
	
	/* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

	const data = workOrders.map((wo) => {
		let technician = employees?.find((e) => e.id == wo.technicianId);
		return ({ ...wo,
			_stripe_color: Object.values(WORKORDERS).find((o) => o.id == wo.statusId)?.color,
			_no_select: (wo.statusId != WORKORDERS.COMPLETE.id),
			startDate: moment(wo.startDate.seconds, 'X').format('MM/DD/YYYY'),
			checked: state.work_orders?.find((w) => w.id == wo.id) ? true : false,
			technician: technician?.contact.firstName + ' ' + technician?.contact.lastName
		});
	});

	const columns = [
		{ name: 'Custom ID', field: 'customId', width: 30 },
		{ name: 'Start', field: 'startDate', width: 35 },
		{ name: 'Technician', field: 'technician', width: 35 },
		{ name: 'Description', field: 'description' },
	];

	/* Render ------------------------------------------------------------------------------------------------------------------------------------*/

	return (

		<ModalForm {...props}
			history={props.history}
			modal_header={'Generate Invoice'}
			cancel_button_title="Cancel"
			save_button_title={'Generate Invoice'}
			submitFormHandler={() => submitForm()}
			show_spinner={invoices.invoice_save_pending}
		>
			<h2>Select Work Orders to Include on the Invoice:</h2>
			<Table
				data={data}
				show_limit={false}
				select={true}
				on_select={(item, e) => handleSelect(item, e)}
				columns={columns}
                order_by = {{ fields: ['id'], direction: ['desc'] }}
				second_line={'description'}
			/>
		</ModalForm>
	);
};


export default GenerateInvoiceForm;
