import React, { Fragment } from 'react';
import _ from 'lodash';
import { CONTACTS } from 'components/constants';

export function email_fields(contacts, type, id, company) {

	// Default to Billing Contact, or a single Contact on file
	const billingContact = contacts.find((contact) => contact.contactTypeId == CONTACTS.BILLING.id);
	let contactType = _.find(Object.values(CONTACTS), { id: billingContact?.contactTypeId });

	return (
		{
			to: (contacts.length > 1 && billingContact?.email)
				? [{ value: billingContact.email, label: billingContact.email + ' - ' + contactType?.name }]
				: (contacts.length == 1 && contacts[0]?.email)
					? [{ value: contacts[0].email, label: contacts[0].email }]
					: [],
			subject: `${company.companyName} - ${type} #${id}`,
			body: contacts[0].firstName + `,\r\n\r\nPlease see the attached PDF document to review your ${type}.`
		}
	);
}

export function email_form_layout(contacts, record) {

	var contacts_options = [];
	contacts.forEach((contact, index) => {
		if (contact.email) {
			let type = _.find(Object.values(CONTACTS), { id: contact.contactTypeId });
			contacts_options.push({ value: contact.email, label: contact.email + ' - ' + type.name });
		}
	});

	return (
		[{
			column_class: 'col-md-12',
			body: [{
				section:
					<Fragment>
						<h3 className="m-t-none m-b">Email Information</h3>
						<div class="alert alert-info my-3 d-flex align-items-center">
							<i class="fa-solid fa-envelope-circle-check fa-2x mr-3"></i>
							<span>
								You may choose from the list, and/or enter new emails by typing in the box and pressing &lt;Enter&gt;
							</span>
						</div>
					</Fragment>,
				layout: [
					{
						grid: 'col-12',
						label: 'Contact',
						field: 'to',
						type: 'multiselect',
						creatable: true,
						valid: ['required'],
						options: contacts_options
					}, {
						grid: 'col-12',
						label: 'Subject',
						field: 'subject',
						type: 'text',
						valid: ['required'],
					}, {
						grid: 'col-12',
						label: 'Body',
						field: 'body',
						type: 'textarea',
						rows: 10,
						valid: ['required'],
					}],
			},]
		}]
	);
}