import * as actions_admin from 'actions/admin-actions';
import * as actions_quotes from 'actions/quotes-actions';
import * as actions_service_requests from 'actions/service-requests-actions';
import * as actions_work_orders from 'actions/work-orders-actions';
import * as toolbox from 'components/common/toolbox';
import ExpandDetails from 'components/common/expand-details';
import React, { useEffect, useState } from 'react';
import StatusSelect from 'components/common/status-select';
import { APPS, ADDRESSES, REQUESTS, SERVICE_REQUEST_TERMS, URGENCY, WORKORDERS } from 'components/constants';
import { Avatar, CloseX, DashboardDetail, Mapbox, ModalAlert, Spinner } from 'enspire-manager-framework';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { confirmRedirect, numPanels } from 'components/common/toolbox';
import { imageSize } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const _ = require('lodash');

const ServiceRequestDashboard = (props) => {
    
    /* Hooks ------------------------------------------------------------------------------------------------------------------------------------*/
    
    const seg = 5;
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const routeLocation = useLocation();
    
    const admin = useSelector((store) => store.admin);
    const assets = useSelector((store) => store.assets.assets);
	const profiles = useSelector((state) => state.profiles);
    const quotes = useSelector((store) => store.quotes);
    const services = useSelector((store) => store.serviceRequests);
    const service_request = useSelector((store) => store.serviceRequests.service_request);
	const userPermissions = useSelector(store => store.users?.user_permissions);
    const workOrders = useSelector((store) => store.workOrders);
    
    const [processing, setProcessing] = useState(false);
    const [state, setState] = useState({
        mapAddress: null,
        showGenerateInvoice: false,
    });
    
	const profile = profiles.profile;
    const pathname = routeLocation.pathname;
    const segments = pathname.split('/').length;
    const approved_quote = quotes.quote_approved;
    const asset = assets.find((asset) => asset.id == service_request?.assetId);
    const isRecord = (service_request?.type == 'asset_record');
    const serviceType = (isRecord) ? 'Record' : 'Reqeust';

    // const serviceAddress = _.find(profile?.addresses, { id: service_request?.addressId });
    // const source = props?.source;

    /* Effects ------------------------------------------------------------------------------------------------------------------------------------*/

    useEffect(() => {
        dispatch(actions_service_requests.subServiceRequest(params.handle, params.request_id));
        dispatch(actions_work_orders.subWorkOrdersByRequestId(params.handle, params.request_id));

        return () => {
            var unsubscribe = services.service_request_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();

            var unsubscribe = workOrders.work_orders_unsubscribe;
            if (typeof unsubscribe === 'function') unsubscribe();

            dispatch(actions_service_requests.clearServiceRequest());
        };
    }, [params.request_id]);

    // Subscribe by profileId for Customer Dashboard, requestId for Service Request
    useEffect(() => {
        // dispatch(actions_quotes.subQuotesByField(params.handle, 'serviceRequestId', params.request_id));

        // return () => {
        //     dispatch(actions_quotes.clearQuotes());
        //     let unsubscribe = quotes.quotes_unsubscribe;
        //     if (typeof unsubscribe === 'function') unsubscribe();
        // };
    }, [params.request_id, params.customer_id]);

    // Sub to Accepted Quote
    useEffect(() => {
        // dispatch(actions_quotes.subAcceptedQuoteByServiceRequestId(params.handle, params.request_id));

        // return () => {
        //     let unsubscribe = quotes.quote_approved_unsubscribe;
        //     if (typeof unsubscribe === 'function') unsubscribe();
        // };
    }, [params.request_id]);

    // Once customer is loaded, get the address for the map
    useEffect(() => {
        let address = _.find(profiles?.profile?.addresses, { addressTypeId: ADDRESSES.SERVICE });
        if (!address) address = profiles?.profile?.addresses?.[0];
        let mapAddress = (address) ? `${address.addressLine1} ${address.addressLine2} ${address.city} ${address.state} ${address.zip}` : null;
        if (mapAddress) setState(prev => ({ ...prev, mapAddress }));
    }, [profiles?.profile?.id]);

    /* Actions/Handlers ------------------------------------------------------------------------------------------------------------------------------------*/

    const openForm = () => {
        let location = toolbox.modifyPath(props.location.pathname, seg, 'request-form/' + params.request_id);
        history.push({ pathname: location });
    };
    const viewCustomer = () => {
        confirmRedirect("Customers", () => {
            history.go((params.tab_id) ? -2 : -1);
            // let location = toolbox.modifyPath(props.location.pathname, 3, `customers/${service_request.customerId}/form/0/service_requests/${service_request.id}/work_orders`, 9);
            // history.push({ pathname: location });
        });

    };
    const handleGenerateInvoice = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, seg, 'invoice-form/0');
        history.push({ pathname: newLocation });
    };
    // const handleUrgency = (statusTable, statusId) => {
    //     if (statusTable == 'URGENCY') {
    //         dispatch(actions_service_requests.saveServiceRequest(params.handle, { ...service_request, urgencyId: statusId }, settings));
    //     }
    // };
    const openReceivePayment = () => {
        let newLocation = toolbox.modifyPath(props.location.pathname, seg, 'payment-form/0');
        history.push({ pathname: newLocation });
    };
    // const handleChangeStatus = (statusId, reason) => {
    //     switch (statusId) {
    //         case SERVICE_REQUEST_TERMS.SERVICE_CALL.id: {

    //             // Prevent switch if Quote has been approved
    //             if (approved_quote) {
    //                 window.toastr.error('Cannot change status to Service Call when the Quote has been approved', 'Error Switching Status');
    //                 break;
    //             }
    //             // Prevent switch if more than one Quote option exists
    //             if (quotes?.quotes?.[0]?.options.length > 1) {
    //                 window.toastr.error('Estimate with multiple options cannot be converted to Service Call', 'Error Switching Status');
    //                 break;
    //             }

    //             const workOrder = workOrders?.work_orders?.[0];
    //             const quote = quotes?.quotes?.[0];

    //             dispatch(actions_service_requests.convertToServiceCall(params.handle, service_request, workOrder, quote, () => {
    //                 dispatch(actions_settings.statusModalClose());
    //                 let newLocation = toolbox.modifyPath(props.location.pathname, seg+5, 'work_orders/' + workOrder.id + '/items');
    //                 history.push({ pathname: newLocation });
    //             }));
    //             break;
    //         }
    //         case SERVICE_REQUEST_TERMS.ESTIMATE.id: {

    //             // Prevent switch if Work Order has been completed or invoiced
    //             const completed = workOrders?.work_orders?.find((workOrder) => (workOrder.statusId == WORKORDERS.COMPLETE.id || workOrder.statusId == WORKORDERS.INVOICED.id));
    //             if (completed) {
    //                 window.toastr.error('Cannot change status to estimate if Work Order has been completed or invoiced', 'Error Switching Status', 'Error Switching Status');
    //                 break;
    //             }
    //             dispatch(actions_service_requests.convertToEstimate(params.handle, service_request, () => {
    //                 dispatch(actions_quotes.createQuoteFromWorkOrders(params.handle, params.request_id, (quoteId) => {
    //                     dispatch(actions_settings.statusModalClose());
    //                     let newLocation = toolbox.modifyPath(props.location.pathname, seg+5, 'quotes/' + quoteId + '/items');
    //                     history.push({ pathname: newLocation });
    //                 }));
    //             }));
    //             break;
    //         }
    //     }
    // };
    // const handleStatus = (statusTable, statusId) => {
    //     dispatch(actions_settings.statusModalOpen(statusTable, statusId, service_request.id, handleChangeStatus));
    // };
    // const confirmGenerateQuote = () => {
    //     ModalAlert({
    //         title: 'Generate a Quote?',
    //         text: "This will generate a Quote from the open Work Orders on this Service Request",
    //         icon: 'question',
    //         show_cancel: true,
    //         confirm_color: '#8FBC8B',
    //         confirm_text: 'Yes, Create Quote',
    //         callback_success: () => {
    //             setProcessing(true);
    //             dispatch(actions_quotes.createQuoteFromWorkOrders(params.handle, params.request_id, (quoteId) => {
    //                 setProcessing(false);
    //                 let location = toolbox.modifyPath(props.location.pathname, seg, 'form/0/0/service_requests/' + params.request_id + '/quotes/' + quoteId + '/items');
    //                 history.push({ pathname: location });
    //             }));
    //         },
    //     });
    // };
    const confirmGenerateChange = () => {
        ModalAlert({
            title: 'Generate a Change Order?',
            text: "This will generate a new Quote highlighting changes from the currently Accepted Quote",
            icon: 'question',
            show_cancel: true,
            confirm_color: '#8FBC8B',
            confirm_text: 'Yes, Create Change Order',
            callback_success: () => {
                setProcessing(true);
                dispatch(actions_quotes.createChangeOrder(params.handle, params.request_id, (quoteId) => {
                    setProcessing(false);
                    let location = toolbox.modifyPath(props.location.pathname, seg, 'form/0/0/service_requests/' + params.request_id + '/quotes/' + quoteId + '/items');
                    history.push({ pathname: location });
                }));
            },
        });
    };
    const confirmTimeMaterials = () => { };
    const openHelpPanel = (url) => {
        if (window.flutter) {
            window.flutter.callHandler('helpPanel', url); 
        } else {
            dispatch(actions_admin.showHelp(true, url));
        }
	}
	const toggleLightbox = () => {
		let imagesAlt = service_request.imagesAlt?.map((imageUrl, index) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(service_request.imageUrl, 'lg') },
			...(service_request.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}

    /* Constants ------------------------------------------------------------------------------------------------------------------------------------*/

    var contactArray = []; // start will all contacts, remove when assigned an address
    if (profile?.contacts) profile.contacts.forEach((contact) => {
        contactArray.push(contact);
    });

    var quoteDisabled = (!userPermissions?.QUOTES_CREATE)
        ? true
        : (!!approved_quote);
    var changeDisabled = (!approved_quote);
    var invoiceDisabled = !workOrders?.work_orders?.find((workOrder) => (workOrder.statusId == WORKORDERS.COMPLETE.id));
    var paymentDisabled = !userPermissions?.PAYMENTS_RECEIVE;

    const custAvatar = 
        <Avatar
            className="float-right"
            color="white"
            bgColor="saddleBrown"
            size={45}
            fontSize={'10px'}
            name={(params.appId == APPS.SERVICE.id) ? service_request?._displayName : asset?.name }
            image={imageSize(service_request?._imageUrl, 'sm')}
            border="3px solid white"
        />
    
    /* Render ------------------------------------------------------------------------------------------------------------------------------------*/

    return (

		<div className="row position-relative mb-2">
            {service_request
                ? <>
                    <div className="position-absolute animated fadeIn animation-delay-4" style={{ top: '62px', left: '18px', zIndex: 100 }}>
                        { custAvatar }
                    </div>
                    <div id="request-avatar" className={ 'col-12 mb-3' }>
                        <div className="profile-image" onClick={ (service_request.imageUrl) ? toggleLightbox : null }>
                            <div className="mb-2">
                                { !!service_request.imagesAlt?.length &&
                                    <Avatar
                                        style={{ position: 'absolute', left: '70px', top: '55px' }}
                                        color="white"
                                        bgColor="black"
                                        size={35}
                                        fontSize={'10px'}
                                        name={`+${service_request.imagesAlt.length}`}
                                        initials={ false }
                                        border="3px solid white"
                                        role="button"
                                    />
                                }
                                <Avatar
                                    color="white"
                                    bgColor="darkseagreen"
                                    size={90}
                                    name={service_request.customId}
                                    initials={false}
                                    image={imageSize(service_request.imageUrl, 'sm')}
                                    border="4px solid white"
                                    role={ (service_request.imageUrl) ? "button" : null }
                                />
                            </div>

						</div>
                        <div className="profile-info pt-1 pr-2">
                            <div className="d-flex">
                                { isRecord
                                    ?   <h1 className="main-heading heading-service-record align-self-start">
                                            #{service_request.customId} Service Record
                                        </h1>
                                    :   <h1 className="main-heading heading-service-request align-self-start">
                                            #{service_request.customId} Service Request
                                        </h1>
                                }
                                {!window.flutter &&
                                    <div className={ 'animated fadeIn mt-n2 ml-3' } style={{ cursor: 'pointer', height: '10px' }} 
                                        onClick={() => { (numPanels(1)) ? history.go(-1) : history.go(-2) }}>
                                        <i className="fas fa-times" style={{ fontSize: '38px', color: '#bbbbbb' }}></i>
                                    </div>
                                }
                            </div>
                            <h2 className="line-clamp-2 ml-2 mt-1">{service_request.description}</h2>
                        </div>
					</div>

                    <StatusSelect className="ml-4 mr-2"
                        selectedStatusId={service_request.statusId}
                        statusTypes={Object.values(REQUESTS)}
                        statusTable={'REQUESTS'}
                        static={true}
                    />
                    <StatusSelect className="mr-2"
                        selectedStatusId={service_request.serviceRequestTermsId}
                        statusTypes={Object.values(SERVICE_REQUEST_TERMS)}
                        statusTable={'SERVICE_REQUEST_TERMS'}
                        // onClick={handleStatus}
                        static={true}
                    />
                    {/* <StatusSelect className="mr-2"
                        selectedStatusId={service_request.urgencyId}
                        statusTypes={Object.values(URGENCY)}
                        statusTable={'URGENCY'}
                        onClick={handleUrgency}
                    /> */}

                    <div className="col-12">
                        <div id="details" className="mb-2">
                            <ExpandDetails more="Show More">
                                <div id="service-request-details" className="mt-3 mb-2">
                                    { params.appId == APPS.SERVICE.id
                                        ?   <>
                                                <DashboardDetail field={ custAvatar } value={<span>{service_request._displayName}<br/>{state.mapAddress}</span>} />
                                                <DashboardDetail field={ 'Request Date' } value={ toolbox.dateOrDash(service_request.requestDate, 'MMM DD, YYYY h:mm A') } />
                                                <DashboardDetail field={ 'Description' } value={ service_request.description } type="text" />
                                            </>
                                        :   <>
                                                <DashboardDetail field={ 'Asset' } value={ `#${service_request._unitNumber} ${service_request._name}` } />
                                                <DashboardDetail field={ 'Request Date' } value={ toolbox.dateOrDash(service_request.requestDate, 'MMM DD, YYYY h:mm A') } />
                                                <DashboardDetail field={ 'Miles/Hours' } value={ service_request.milesHours } />
                                                <DashboardDetail field={ 'Summary' } value={ service_request.description } />
                                                <DashboardDetail field={ 'Suggested' } value={ service_request.suggested } />
                                                <DashboardDetail field={ 'Completed' } value={ service_request.totalCompleted } type="usd" />
                                                <DashboardDetail field={ 'Total Cost' } value={ service_request.totalCost } type="usd" />
                                            </>
                                    }
                                </div>
                            </ExpandDetails>

                            <div className="mt-3">
                                { service_request.deleted
                                ?   <button type="button" className="btn btn-outline btn-danger float-left ml-3 mt-1">ARCHIVED</button>
                                :   <>
                                        { !isRecord &&
                                            <DropdownButton 
                                                title="Service Request Actions &nbsp;"
                                                variant="primary" size="sm" className="d-inline ml-2 mb-2"
                                                onClick={(event) => { if (event?.stopPropagation) event?.stopPropagation(); }}
                                            >
                                                {/* <Dropdown.Item eventKey="1" onClick={confirmGenerateQuote} disabled={quoteDisabled}>Generate Quote</Dropdown.Item> */}
                                                <Dropdown.Item eventKey="2" onClick={confirmGenerateChange} disabled={changeDisabled}>Change Order</Dropdown.Item>
                                                <Dropdown.Item eventKey="3" onClick={confirmTimeMaterials} disabled>Time & Materials</Dropdown.Item>
                                                <Dropdown.Item eventKey="4" onClick={handleGenerateInvoice} disabled={invoiceDisabled}>Generate Invoice</Dropdown.Item>
                                                <Dropdown.Item eventKey="5" onClick={openReceivePayment} disabled={paymentDisabled}>Receive Payment</Dropdown.Item>
                                            </DropdownButton>
                                        }
                                        <div className="float-right">
                                            <button type="button" 
                                                className={'btn btn-default btn-sm mr-2' }
                                                disabled={!userPermissions.SERVICE_REQUESTS_EDIT}
                                                onClick={openForm}
                                            >{ `Edit Service ${serviceType}` }
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        { service_request.statusId == REQUESTS.COMPLETE.id &&
                            <div class="alert alert-info mt-3 mb-0 d-flex align-items-center">
                                <i class="fa-solid fa-circle-info fa-2x mr-3"></i>
                                <span>
                                    This Service Request has been Completed!  The next step is to generate an <strong>Invoice</strong> from the <strong>Actions</strong> button above.
                                </span>
                            </div>
                        }

                        {/* { params.appId == APPS.SERVICE.id &&
                            <ExpandDetails more="Map" top={ 15 } limitHeight={ 40 } default="closed">
                                <div className="row">
                                    <h2 className="ml-3" style={{ paddingRight: '70px' }}>{state.mapAddress}</h2>
                                    <div className="mapbox-container mt-2">
                                        {state.mapAddress &&
                                            <Mapbox map_address={state.mapAddress} aspect_ratio={3} />
                                        }
                                    </div>
                                </div>
                            </ExpandDetails>
                        } */}
                    </div>
                </>
                : <Spinner center />
            }
        </div>
    );
};

export default ServiceRequestDashboard;
