import * as actions_users from 'actions/users-actions';
import * as actions_service_requests from 'actions/service-requests-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { APPS, REQUESTS } from 'components/constants';
import { Avatar, Table } from 'enspire-manager-framework';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { imageSize } from 'components/common/toolbox';

const ResultsServiceRequests = (props) => {
    
    /* Hooks --------------------------*/
    
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();

    const user = useSelector((store) => store.users.user);
    const serviceRequests = useSelector((store) => store.serviceRequests);
    const orderedServiceRequests = _.orderBy(serviceRequests.service_requests_dashboard, ['_name'], ['asc']);

    const [data, setData] = useState([]);

    /* UseEffect --------------------------*/

    useEffect(() => {
        if (params.widget_id == 'dashboard') {
            dispatch(actions_service_requests.clearDashboardServiceRequests());
        } else {
            dispatch(actions_service_requests.subDashboardServiceRequests(params.handle, params.appId, params.widget_id));
        }
        
        return () => {
			const unsubscribe = serviceRequests.service_requests_dashboard_unsubscribe;
			if (typeof unsubscribe == 'function') unsubscribe();
		};
    }, [params.handle, params.appId, params.widget_id]);

    useEffect(() => { 
        var service_requests = [];
        var currentEntityId = null;
        orderedServiceRequests?.forEach((serviceRequest) => {

            let entityId = (params.appId == APPS.SERVICE.id) ? serviceRequest.customerId : serviceRequest.assetId;
            
            let status = _.find(Object.values(REQUESTS), { id: serviceRequest.statusId });
            
            if (entityId != currentEntityId) {
                currentEntityId = entityId;
                service_requests.push({ ...serviceRequest,
                    status: status?.name,
                    _jsx:
                    <div className="d-flex align-items-center p-2" style={{ backgroundColor: '#cccccc' }}>
                        <Avatar className=""
                            color="white"
                            bgColor="saddleBrown"
                            size={45}
                            fontSize={'20px'}
                            name={(params.appId == APPS.SERVICE.id) ? serviceRequest._displayName : serviceRequest._name}
                            image={imageSize(serviceRequest._imageUrl, 'sm')}
                            imageWidth={60}
                            border="4px solid white"
                            role={ (serviceRequest._imageUrl) ? "button" : null }
                        />
                        <h2 className="ml-3 m-0 text-white">{
                            ((params.appId == APPS.SERVICE.id) ? serviceRequest._displayName : serviceRequest._name) + 
                            ((serviceRequest._unitNumber) ? ' #' + serviceRequest._unitNumber : '')}</h2>
                    </div> 
                });
            }

            service_requests.push({ ...serviceRequest,
                status: status?.name,
                _stripe_color: status?.color,
            });
        });
        setData(service_requests);
    }, [serviceRequests.service_requests_dashboard]);

    /* Actions ----------------------------*/

    const openServiceRequest = async (serviceRequest) => {
        let entityId = (params.appId == APPS.SERVICE.id) ? serviceRequest.customerId : serviceRequest.assetId;
        toolbox.listLink(history, routeLocation?.pathname, 4, `${entityId}/form/0/service_requests/${serviceRequest.id}/tab`, null, 9)
    }

    /* Constants --------------------------*/

    const columns = [
        { name: 'Requested', field: 'requestDate', type: 'timestamp', format: 'MMM Do', width: 25 },
        ...(params.appId == APPS.ASSETS.id) ? [{ name: 'Asset', field: '_name', width: 75 }] : [],
        ...(params.appId == APPS.SERVICE.id) ? [{ name: 'Customer', field: '_displayName', width: 75 }] : [],
        { name: 'Summary', field: 'description' },
    ];

    return (

        <div className="bg-white px-3 py-1">
            <Table
                data={data}
                columns={columns}
                active_field={'id'}
                active_id={params.tab2_id}
                second_line={'description'}
                group_by={{ fields: ['statusId'], direction: ['desc'], display: ['status'] }}
                click_callback={ openServiceRequest }
                chevron={ true }
            >
            </Table>
        </div>
    );
};

export default ResultsServiceRequests;