import * as toolbox from 'components/common/toolbox';
import ActivityLog from 'components/activity-log/activity-log';
import AttachmentsTab from 'components/attachments/attachments-tab';
import Authorize from 'components/common/authorize';
import CatchErrors from 'components/common/catch-errors';
import LineItemsTab from 'components/line-items/line-items-tab/line-items-tab';
import React from 'react';
import { APPS, LOG, PERMISSIONS } from 'components/constants';
import { Route, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { numPanels } from 'components/common/toolbox';

export default function WorkOrderTabs(props) {

    const seg = 11;
    const match = useRouteMatch();
    const params = useParams();
    const history = useHistory();
    const routeLocation = useLocation();

    const segments = routeLocation.pathname.split('/').length;

    function tab_active(value) {
        return (params.tab === value) ? ' active' : ''; // returns 'active' if value tab is active
    }
	function link_tab(value, event) {
		event.preventDefault();
		// let num = (segments >= 12) ? -2 : (segments >= 10) ? -1 : 0;
		// if (num) history.go(num);

		setTimeout(() => {
			history.replace(toolbox.modifyPath(props.location.pathname, seg, value, seg));
		}, 50);
	}

    return (
        <>
            <Route path={match.path} render={(route_props) =>
                <div className="tabs-container mt-3">
                    <ul className={ `nav nav-tabs underline ${(numPanels(1))?'scroll-tabs':''}` } role="tablist">
                        <li><a className={'nav-link' + tab_active('items')} onClick={link_tab.bind(this, 'items')}> Line Items</a></li>
                        <li><a className={'nav-link' + tab_active('external-invoices')} onClick={link_tab.bind(this, 'external-invoices')}> External Invoices</a></li>
                        <li><a className={'nav-link' + tab_active('attachments')} onClick={link_tab.bind(this, 'attachments')}> Attachments</a></li>
                        <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                            <li><a className={'nav-link' + tab_active('activity')} onClick={link_tab.bind(this, 'attachments')}>Activity</a></li>
                        </Authorize>
                    </ul>

                    <div className="tab-content">
                        <div role="tabpanel" className={'tab-pane' + tab_active('items')}>
                            <div className="panel-body">
                                <Route path={match.path} render={(route_props) =>
                                    <CatchErrors>
                                        <LineItemsTab {...route_props} source={'work-orders'} setFocused={ props.setFocused } />
                                    </CatchErrors>
                                } />
                            </div>
                        </div>
                        <div role="tabpanel" className={'tab-pane' + tab_active('external-invoices')}>
                            <div className="panel-body">
                                <Route path={match.path} render={(route_props) =>
                                    <CatchErrors>
                                        <AttachmentsTab {...route_props} 
                                            id={ params.work_order_id } 
                                            columnId="column4"
                                            button="+ Ext Invoice"
                                            field="external_invoices"
                                            prefix="WORK_ORDER" 
                                            singular="work_order" 
                                            source="work-orders" 
                                            store="workOrders" 
                                            title="Ext Invoices"
                                            setFocused={ props.setFocused }
                                        />
                                    </CatchErrors>
                                } />
                            </div>
                        </div>
                        <div role="tabpanel" className={'tab-pane' + tab_active('attachments')}>
                            <div className="panel-body">
                                <Route path={match.path} render={(route_props) =>
                                    <CatchErrors>
                                        <AttachmentsTab {...route_props} 
                                            id={ params.work_order_id } 
                                            columnId="column4"
                                            prefix="WORK_ORDER"
                                            singular="work_order" 
                                            source="work-orders" 
                                            store="workOrders" 
                                            setFocused={ props.setFocused }
                                        />
                                    </CatchErrors>
                                } />
                            </div>
                        </div>
                        <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                            <div role="tabpanel" className={'tab-pane' + tab_active('activity')}>
                                <div className="panel-body">
                                    <Route path={props.match.path} render={(route_props) =>
                                        <CatchErrors>
                                            <ActivityLog {...route_props} 
                                                id="work_order_id" 
                                                prefix="WORK_ORDER" 
                                                singular="work_order"
                                                store="workOrders" 
                                                type={ `${LOG.WORK_ORDER.key}` } 
                                            />
                                        </CatchErrors>
                                    } />
                                </div>
                            </div>
                        </Authorize>
                    </div>
                </div>

            } />
        </>
    );
};

