
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Ibox, Table } from 'enspire-manager-framework';
import { APPS, WORKORDERS } from 'components/constants';
import { numPanels } from 'components/common/toolbox';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const WorkOrdersTab = (props) => {
    
    /* Hooks --------------------------*/
    
    const seg = 3;
    const params = useParams();
    const history = useHistory();
	const routeLocation = useLocation();

    const workOrders = useSelector((store) => store.workOrders.work_orders);
    const employees = useSelector((store) => store.employees);

    const [data, setData] = useState([]);

	/* Effects --------------------------*/

    useEffect(() => {
        var work_orders = [];
        workOrders?.forEach((order) => {
            let status = _.find(Object.values(WORKORDERS), { id: order.statusId });
            let employee = employees.employees?.find((o) => o.id == order.technicianId);
            work_orders.push({...order,
                name: ((employee)
                    ? (employee?.contact?.firstName || employee?.contact?.lastName) 
                        ? employee?.contact?.firstName + ' ' + employee?.contact?.lastName 
                        : 'Unknown'
                    : 'Unassigned'),
                status: status?.name,
                _stripe_color: status?.color,
            });
        });
        setData(work_orders);
    }, [workOrders]);
        
    // AutoAdvance to the first item
    useEffect(() => {
        if (numPanels(3) && params.tab == 'work_orders' && params.tab_id == 'auto' && data.length) {
            var location = toolbox.modifyPath(props.location.pathname, seg+6, 'work_orders', seg+6);
            history.replace({ pathname: location });
            setTimeout(() => {
                var location = toolbox.modifyPath(props.location.pathname, seg+6, `work_orders/${data[0]?.id}/items`);
                history.push({ pathname: location });
            }, 50);
        }
    }, [params.tab, params.tab_id, data]);
    
    /* Constants --------------------------*/

    const columns = [
        { name: 'ID', field: 'customId', nowrap: true, width: 15 },
        { name: 'Technician', field: 'name', width: 25 },
        { name: 'Summary', field: 'description', with: 60 },
    ];

    return (
        <Ibox title={"Work Orders"}
            button={" + Work Order "}
            button_callback={() => {
                let location = toolbox.modifyPath(props.location.pathname, seg+2, 'work-order-form/0', seg+9);
                history.push({ pathname: location });
            }}
        >
            <Table id="work-order-tab"
                active_field={'id'}
                active_id={params.tab_id}
                chevron={true}
                click_callback={(workOrder) => {
                    let location = toolbox.modifyPath(props.location.pathname, seg+7, workOrder.id + '/items', seg+9);
                    let segments = props.location.pathname.split('/').length;
                    
                    if (segments < 12) {
                        history.push({ pathname: location });
                    } else {
                        history.replace({ pathname: location });
                    }
                }}
                columns={columns}
                data={data}
                highlight_search={true}
                limit={10}
                order_by = {{ fields: ['id'], direction: ['desc'] }}
                show_search={false}
            >
            </Table>
        </Ibox>
    );
};

export default WorkOrdersTab;