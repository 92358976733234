import * as actions_admin from 'actions/admin-actions';
import * as actions_authentication from 'actions/authentication-actions';
import * as toolbox from 'components/common/toolbox';
import Authorize from 'components/common/authorize';
import React, { useEffect, useState } from 'react';
import StatusModal from 'components/common/status-modal';
import _ from 'lodash';
import store from 'store';
import { APPS, ACCOUNTS, PERMISSIONS, ROLES } from 'components/constants';
import { Link } from "react-router-dom";
import { numPanels } from 'components/common/toolbox';
import { sortByPosition } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Lightbox from "yet-another-react-lightbox";
import { Thumbnails, Zoom } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const moment = require('moment');

export default function Navbar(props) {
    
    const seg = 2;
	const params = useParams();
	const history = useHistory();
    const dispatch = useDispatch();
	const routeLocation = useLocation();
    
    const admin = useSelector((store) => store.admin);
    const user = useSelector((store) => store.users.user);
    const assetTypes = useSelector((store) => store.settings.settings_asset_types)?.filter((o) => !o.deleted);
    const company = useSelector((store) => store.company.company);
    const selectedCustomerId = useSelector((store) => store.assets.selected_customer_id);
    const selectedType = useSelector((store) => store.assets.selected_asset_type_id);
    const statusModal = useSelector((store) => store.settings.statusModal);
    
    const targetAssetTypeId = (selectedType && assetTypes?.find((at) => at?.id == selectedType)) ? selectedType : '0';
    const targetCustomerId = (selectedCustomerId) ? selectedCustomerId : '0';
    const segments = routeLocation.pathname.split('/');
    
    const [focusSearch, setFocusSearch] = useState(false);

    const flutter = window.flutter;

    /* Hooks --------------------------*/
    
    useEffect(() => {
        if (company?.appIds?.length && !company?.appIds?.includes(params.appId)) {
            selectApp(company.appIds[0]);
        } else {
            var bodyStyles = document.body.style;
            bodyStyles.setProperty('--theme-primary', `var(--theme${params.appId}-primary)`);
            bodyStyles.setProperty('--theme-primary-hover', `var(--theme${params.appId}-primary-hover)`);
            bodyStyles.setProperty('--theme-secondary', `var(--theme${params.appId}-secondary)`);
            bodyStyles.setProperty('--theme-alternate', `var(--theme${params.appId}-alternate)`);
        }
    }, [params.appId]);
    
    /* Actions --------------------------*/

    const logOut = (event) => {
        event.preventDefault();
        store.dispatch(actions_authentication.logout());
    }
    const selectApp = (appId) => {
        toolbox.selectApp(appId, routeLocation, history);
    }
    const selectLocation = (handle) => {
        toolbox.selectLocation(handle, routeLocation, history);
    }
    const openHelp = () => dispatch(actions_admin.showHelp(true, '/getting-started/'));

    const MobileLink = (props) => 
        <li className="nav-item">
            <Link replace={ !props.push } id={props.id} className={props.className} to={props.to} data-toggle={ (flutter)?'':'collapse'} data-target=".navbar-collapse">{props.name} </Link>
        </li>

    return (

        <div className="row border-bottom white-bg" style={{ marginLeft: (props.hideMenu) ? '0':'80px', ...(flutter)?{display: 'none'}:{} }}>
            <nav className="navbar navbar-expand-lg navbar-static-top" 
                style={{ justifyContent: 'normal', ...(props.hideMenu) ? { backgroundColor: '#a68d42' } : {}}} 
                role="navigation"
            >
                <span className="position-relative" style={{ flexWrap: 'nowrap' }}>
                    <button className="navbar-brand" style={{ border: 'none' }}>
                        <a className="navbar-toggler collapsed m-0 p-0 pr-4" type="button" data-toggle="collapse" data-target="#navbar">
                            <i className="fa fa-bars" style={{ fontSize: '18px' }}></i>
                        </a>
                        <img src="images/logo-white-small.png" height="25" />
                        {process.env.REACT_APP_FIREBASE_ENV == 'development' &&
                            <small style={{ color: 'red' }}>{process.env.REACT_APP_FIREBASE_ENV?.toUpperCase()}</small>
                        }
                    </button>
                </span>

                <div className="navbar-collapse collapse" id="navbar">
                    { user.roles?.includes('9999') &&
                        <ul className="navbar-nav pr-3">
                            <li className="nav-item dropdown" style={{ backgroundColor: 'orange' }}>
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                    { _.find(company?.locations, { handle: params.handle })?.name }&nbsp;
                                </a>
                                <div className="dropdown-menu">
                                    {
                                        ACCOUNTS?.map((account, index) => {
                                            return (
                                                <a key={ account.handle } className="dropdown-item" onClick={ () => selectLocation(account.handle) }>{ account.name }</a>
                                            );
                                        })
                                    }
                                </div>
                            </li>
                        </ul>
                    }
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                { _.find(company?.locations, { handle: params.handle })?.name }&nbsp;
                            </a>
                            <div className="dropdown-menu">
                                {
                                    company?.locations?.map((location, index) => {
                                        return (
                                            <a key={ location.handle } className="dropdown-item" onClick={ () => selectLocation(location.handle) }>{ location.name }</a>
                                        );
                                    })
                                }
                            </div>
                        </li>
                        { company?.appIds?.includes(APPS.SERVICE.id) &&
                            <li className={ `nav-item ${(params.appId == APPS.SERVICE.id)?'active':''}` } onClick={ () => selectApp(APPS.SERVICE.id) }>
                                <a className="nav-link">Service Manager</a>
                            </li>
                        }
                        { company?.appIds?.includes(APPS.ASSETS.id) &&
                            <li className={ `nav-item ${(params.appId == APPS.ASSETS.id)?'active':''}` } onClick={ () => selectApp(APPS.ASSETS.id) }>
                                <a className="nav-link">Asset Manager</a>
                            </li>
                        }
                        { company?.appIds?.includes(APPS.LOGISTICS.id) &&
                            <li className={ `nav-item ${(params.appId == APPS.LOGISTICS.id)?'active':''}` } onClick={ () => selectApp(APPS.LOGISTICS.id) }>
                                <a className="nav-link">Logistics Manager</a>
                            </li>
                        }
                    </ul>

                    <ul className="nav navbar-nav mr-auto bg-white">    
                        { props.hideMenu &&
                            <>
                                <MobileLink name="Dashboard" id="link-dashboard" to={`/${props.handleApp}/dashboard`} />
                                <Authorize appId={ APPS.ASSETS.id } perm={ PERMISSIONS.ASSETS_VIEW }>
                                    <MobileLink name="Assets" id="link-assets" to={`/${props.handleApp}/assets`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.CUSTOMERS_VIEW }>
                                    <MobileLink name="Customers" id="link-customers" to={`/${props.handleApp}/customers`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                                    <MobileLink name="Service" id="link-service" to={`/${props.handleApp}/service`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.INVENTORY_VIEW }>
                                    <MobileLink name="Inventory" id="link-inventory" to={`/${props.handleApp}/inventory`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.VENDORS_VIEW }>
                                    <MobileLink name="Vendors" id="link-vendors" to={`/${props.handleApp}/vendors`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.SCHEDULE_VIEW }>
                                    <MobileLink name="Schedule" id="link-schedule" to={`/${props.handleApp}/schedule/${moment().format('YYYY-MM-DD')}`} />
                                </Authorize>
                                <Authorize perm={ PERMISSIONS.EMPLOYEES_VIEW }>
                                    <MobileLink name="Employees" id="link-employees" to={`/${props.handleApp}/employees`} />
                                </Authorize>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" id="settings-submenu" data-toggle="dropdown">Settings</a>
                                    <ul className="dropdown-menu" aria-labelledby="settings-submenu">
                                        <Authorize perm={ PERMISSIONS.SETTINGS_COMPANY }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Company" id="link-settings-company" to={`/${props.handleApp}/settings/company`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_DEPARTMENTS }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Departments" id="link-settings-departments" to={`/${props.handleApp}/settings/departments`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_TIME_CLOCK }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Time Clock" id="link-settings-timeclock" to={`/${props.handleApp}/settings/timeclock`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_ASSETS }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Assets" id="link-settings-assets" to={`/${props.handleApp}/settings/assets`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_INVENTORY }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Inventory" id="link-settings-inventory" to={`/${props.handleApp}/settings/inventory`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_SERVICE }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Schedule" id="link-settings-service" to={`/${props.handleApp}/settings/service`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_QUOTES }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Quotes" id="link-settings-quotes" to={`/${props.handleApp}/settings/quotes`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_INVOICES }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Invoices" id="link-settings-invoices" to={`/${props.handleApp}/settings/invoices`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.SETTINGS_INVOICES }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Taxes" id="link-settings-taxes" to={`/${props.handleApp}/settings/taxes`} />
                                        </Authorize>
                                        <Authorize roleId={ ROLES.OWNER.id }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Permissions" id="link-settings-permissions" to={`/${props.handleApp}/settings/permissions`} />
                                        </Authorize>
                                        <Authorize perm={ PERMISSIONS.ACTIVITY_LOGS_VIEW }>
                                            <MobileLink push={ segments[2] !== 'settings' } name="Activity Logs" id="link-settings-activity" to={`/${props.handleApp}/settings/activity-log`} />
                                        </Authorize>
                                        <MobileLink push={ segments[2] !== 'settings' } name="User Preferences" id="link-user-prefs" to={`/${props.handleApp}/settings/user_preferences`} />
                                        <MobileLink push={ segments[2] !== 'settings' } name="Delete Account" id="link-delete-account" to={`/${props.handleApp}/account_delete`} className="text-danger" />
                                    </ul>
                                </li>
                                <li className="nav-item"><p className="float-right px-4">{ window.build }</p></li>
                            </> 
                        }
                    </ul>
                    { numPanels(3) &&
                        <div className="navbar-header bg-white" style={{ width: (focusSearch) ? '40%':'20%', transition: 'width .4s' }}>
                            <form role="search" className="navbar-form-custom" action="search_results.html">
                                <div className="form-group">
                                    <input type="text" 
                                        id="top-search"
                                        className="form-control" 
                                        style={{ width: '100%' }} 
                                        name="top-search" 
                                        placeholder="Search for something..." 
                                        onFocus={ () => setFocusSearch(true) }
                                        onBlur={ () => setFocusSearch(false) }
                                    />
                                </div>
                            </form>
                        </div>
                    }
                    <ul className="nav navbar-top-links navbar-right bg-white pr-3">
                        <li><a title="help" id="link-help" style={{ padding: '10px' }} role="button" onClick={openHelp}> <i className="fas fa-question-circle fa-2x text-muted"></i></a></li>
                        <li><a title="logout" id="link-logout" style={{ padding: '10px' }} role="button" onClick={logOut}> <i className="fas fa-sign-out-alt fa-2x text-muted"></i></a></li>
                    </ul>
                </div>
            </nav>
            {statusModal &&
                <StatusModal />
            }
            <Lightbox
                open={admin.lightbox_show}
                close={() => dispatch(actions_admin.lightboxShow(false))}
                slides={admin.lightbox_sources}
                plugins={[Thumbnails, Zoom]}
                zoom={{
                    maxZoomPixelRatio: 4,
                    pinchZoomDistanceFactor: 200,
                }}
                carousel={{ finite: true }}
                render={{
                  buttonPrev: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                  buttonNext: admin.lightbox_sources.length <= 1 ? () => null : undefined,
                }}
                toolbar={{
                    buttons: [
                      <button key="my-button" type="button" className="yarl__button">
                        <span className="material-icons">add_a_photo</span>
                      </button>,
                      "close",
                    ],
                }}
            />
        </div>
    );
}
