import CheckinWorkOrders from 'components/work-orders/check-in-work-orders';
import React from 'react';
import ResultsWorkOrders from '../components/results-work-orders';
import { APPS, COUNTS } from 'components/constants';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Expanding } from 'components/common/expanding';
import { Widget } from 'components/dashboard/components/widget';
import { WidgetTask } from 'components/dashboard/components/widget-task';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function MyTasks(props) {

	const params = useParams();

    const counts = useSelector((store) => store.admin.dashboard_counts);
    const MyWorkOrders = useSelector((store) => store.workOrders.work_orders_technician);

    return (
		<div className="row mb-2 no-gutters" >

			{/* TASKS ------------------------------------------------------------------------------------- */}

			<div className="col-12 p-1">
				<Widget 
					active={ params.widget_id == 'my-workorders' }
					color_number={ 3 } 
					icon={ <i className="fa-duotone fa-solid fa-file-user fa-3x"></i> }
					text={ 'My Work Orders' }
					title={ MyWorkOrders?.length }
					value="my-workorders"
					onClick={ props.handleClick }
				/>


				{/* <WidgetTask 
					active={ params.widget_id == 'my-workorders' }
					badge={ MyWorkOrders?.length }
					icon={ <i className="fa-duotone fa-solid fa-file-user fa-3x"></i> }
					text={ 'My Work Orders' }
					value="my-workorders"
					onClick={ props.handleClick }
				/> */}
			</div>
			{/* <div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'unassigned-wo' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_WORKORDERS_UNASSIGNED.id] ?? 0 : counts?.[COUNTS.SERVICE_WORKORDERS_UNASSIGNED.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-xmark fa-3x"></i> }
					text={ 'Unassigned Work Orders' }
					value="unassigned-wo"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'inprogress-wo' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_WORKORDERS_INPROGRESS.id] ?? 0 : counts?.[COUNTS.SERVICE_WORKORDERS_INPROGRESS.id] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-rotate fa-3x"></i> }
					text={ 'Work Orders in Progress' }
					value="inprogress-wo"
					onClick={ props.handleClick }
				/>
			</div>
			<div className="col-3 p-1">
				<WidgetTask 
					active={ params.widget_id == 'incomplete-wo' }
					badge={ (params.appId == APPS.ASSETS.id) ? counts?.[COUNTS.ASSETS_WORKORDERS_INCOMPLETE] ?? 0 : counts?.[COUNTS.SERVICE_WORKORDERS_INCOMPLETE] ?? 0 }
					icon={ <i className="fa-duotone fa-solid fa-file-exclamation fa-3x"></i> }
					text={ 'Incomplete Work Orders' }
					value="incomplete-wo"
					onClick={ props.handleClick }
				/>
			</div> */}

			{/* RESULTS TABLES ---------------------------------------------------------------------------- */}

			<Expanding open={ params.widget_id == 'my-workorders' }>
				<CheckinWorkOrders source="dashboard" />
			</Expanding>
			{/* <Expanding open={ params.widget_id == 'unassigned-wo' }>
				<ResultsWorkOrders />
			</Expanding>
			<Expanding open={ params.widget_id == 'inprogress-wo' }>
				<ResultsWorkOrders />
			</Expanding>
			<Expanding open={ params.widget_id == 'incomplete-wo' }>
				<ResultsWorkOrders />
			</Expanding> */}

		</div>
	);
};
