import * as toolbox from 'components/common/toolbox';
import * as types from './action-types';
import firebase from 'firebase/compat/app';
import { toastr } from 'react-redux-toastr';

const firestore = firebase.firestore();
const _ = require('lodash');
var moment = require('moment'); 
const axios = require('axios');


/*-----------------------------------------------*/
/*  SERVICE ITEMS CATEGORIES
/*-----------------------------------------------*/

export function subServiceItemCategories(handle, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM_CATEGORIES + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/inventory/inventory').doc('service-items').onSnapshot((doc) => {
			var item = { ...doc.data(), id: doc.id };
			var categories = item.serviceItemCategories

			dispatch({ type: types.SERVICE_ITEM_CATEGORIES + '_FULFILLED', data: categories, unsubscribe });
			if (typeof callback === 'function') callback(categories);
		});
	}
}
export function saveServiceItemCategories(handle, categories, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM_CATEGORIES + '_SAVE_PENDING' });

        firestore.collection(handle + '/inventory/inventory').doc('service-items').update({
            serviceItemCategories: categories,
        }).then(() => {
            dispatch({ type: types.SERVICE_ITEM_CATEGORIES + '_SAVE_FULFILLED' });
            if (typeof callback === 'function') callback();

        }).catch((error) => {
            console.error("Error updating document: ", error);
        });
	}
}

/*-----------------------------------------------*/
/*  SERVICE ITEMS
/*-----------------------------------------------*/

export function addServiceItemById(handle, id, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM + '_PENDING' });

		firestore.collection(handle + '/inventory/inventory/service-items/service-items').doc(id).get().then((doc) => {
			var item = { ...doc.data(), id: doc.id };

			dispatch({ type: types.SERVICE_ITEM + '_FULFILLED', data: item });
			dispatch({ type: types.SERVICE_ITEMS + '_ADD', data: item });
			if (typeof callback === 'function') callback(item);
		});
	}
}
export function subServiceItemById(handle, id) {

	return dispatch => {

		dispatch({ type: types.SERVICE_ITEM + '_PENDING' });

		var unsubscribe = firestore.collection(handle + '/inventory/inventory/service-items/service-items').doc(id).onSnapshot((doc) => {
			var item = { ...doc.data(), id: doc.id };

			dispatch({ type: types.SERVICE_ITEM + '_FULFILLED', data: item, unsubscribe });
		});
	}
}
export function subServiceItemsByWorkOrderID(handle, work_order_id, callback) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDER_ITEMS + '_CLEAR' });
		dispatch({ type: types.WORK_ORDER_ITEMS + '_PENDING' });

		let unsubscribe = firestore.collection(handle + '/work-orders/work-orders/' + work_order_id + '/serviceItems').doc(work_order_id).onSnapshot(querySnapshot => {

			let serviceItems = (querySnapshot.exists) ? querySnapshot.data().serviceItems : [];

			dispatch({ type: types.WORK_ORDER_ITEMS + '_FULFILLED', data: serviceItems, unsubscribe });
			if (typeof callback === 'function') callback(serviceItems);
		});
	}
}
export function subServiceItemsByQuoteID(handle, quote_id) {

	return dispatch => {

		dispatch({ type: types.WORK_ORDER_ITEMS + '_CLEAR' });
		dispatch({ type: types.WORK_ORDER_ITEMS + '_PENDING' });

		let unsubscribe = firestore.collection(handle + '/quotes/quotes/' + quote_id + '/serviceItems').doc(quote_id).onSnapshot(querySnapshot => {

			let serviceItems = (querySnapshot.exists) ? querySnapshot.data().serviceItems : [];

			dispatch({ type: types.WORK_ORDER_ITEMS + '_FULFILLED', data: serviceItems, unsubscribe });
		});
	}
}
export function saveServiceItem(handle, serviceItem, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM + "_SAVE_PENDING" });

		var id = serviceItem.id;
		const batch = firestore.batch();

		if (!parseInt(id)) {
			id = await nextServiceItemId(handle, batch);
			serviceItem.created = new Date();
			serviceItem.deleted = false;
		}

		delete serviceItem.id;
		serviceItem.modified = new Date();

		batch.set(firestore.collection(handle + '/inventory/inventory/service-items/service-items').doc(id), { ...serviceItem }, { merge: true });
		
		batch.commit().then(() => {

			dispatch({
				type: types.SERVICE_ITEM + '_SAVE_FULFILLED',
				data: serviceItem,
			});

			toastr.success('The Service Item record has been successfully saved/updated');
			if (typeof callback === 'function') callback(id);

		}).catch((error) => {
			toolbox.process_error(error, 'Service Item NOT Saved');
		});
	}
}
export function archiveServiceItem(handle, serviceItem, callback) {

	return async dispatch => {

		dispatch({ type: types.SERVICE_ITEM + "_SAVE_PENDING" });

		var id = serviceItem.id;
		const batch = firestore.batch();

		delete serviceItem.id;
		serviceItem.modified = new Date();

		batch.set(firestore.collection(handle + '/inventory/inventory/service-items/service-items').doc(id), { deleted: true }, { merge: true });
		
		batch.commit().then(() => {

			dispatch({ type: types.SERVICE_ITEM + '_SAVE_FULFILLED' });

			window.toastr.success('The Service Item record has been Archived', 'Service Item Archived!');
			if (typeof callback === 'function') callback();

		}).catch((error) => {
			toolbox.process_error(error, 'Service Item NOT Saved');
		});
	}
}

/*-----------------------------------------------*/
/*  FUNCTIONS
/*-----------------------------------------------*/

async function nextServiceItemId(handle, batch) {
	let collection = handle + '/inventory/inventory';
	const table = 'service-items';
	const field = 'nextServiceItemId';
	const startingId = 1000;

	return toolbox.nextId(collection, batch, table, field, startingId);
}
