import * as actions_admin from 'actions/admin-actions';
import * as actions_attachments from 'actions/attachments-actions';
import * as toolbox from 'components/common/toolbox';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import { Ibox, Table } from 'enspire-manager-framework';
import { LOG, ACTION } from 'components/constants';
import { animateScroll as scroll } from 'react-scroll';
import { confirmDelete, imageSize } from 'components/common/toolbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const AttachmentsTab = (props) => {

    // props.id - id for attachment doc
    // props.source - path to doc e.g. 'assets' to read/write '[path]/[path]/[id]' 
    // props.prefix - prefix for Action Type e.g. [PREFIX]_ATTACHMENTS
    // props.field - for changing from default attachments
    // props.store & props.singular for accessing store e.g. useSelector((store) => [store].[singular]);

	const seg = 2;
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
	const routeLocation = useLocation();
    const pathname = routeLocation.pathname;
   
	const admin = useSelector((store) => store.admin);
    const table = useSelector((store) => store[props.store]);
    const user_permissions = useSelector(store => store.users?.user_permissions);
    
    const field = (props.field) ? props.field : 'attachments';
    const attachments = table?.[props.singular + '_' + field] ?? [];
    const defaultAttach = (props.defaultAttach) ? props.defaultAttach : [];

    const [ data, setData ] = useState([]);
    const [ unsavedDefault, setUnsavedDefault ] = useState(false);
    
    useEffect(() => {
		dispatch(actions_attachments.subAttachments(params.handle, props.id, props.source, props.prefix, { field }));
		return () => {
			const unsubscribe = table[props.singular + '_attachments_unsubscribe'];
			if (typeof unsubscribe == 'function') unsubscribe();
		};
	}, [params.handle, props.id, props.source]);

	useEffect(() => {
        setUnsavedDefault(false);
        var data = [];
        let index = 0;
        
        // Check if Default exist that have not been added to the Attachments record
        defaultAttach.forEach((def) => {
            let found = false;
            attachments.forEach((attach, i) => {
                if (attach.name == def.name) {
                    data.push({ ...attach, 
                        index: i,
                        isDefault: true, 
                        def: <i className="fas fa-check-circle fa-xl"></i>, 
                        images: (def.imageUrl) ? <i className="fa-solid fa-camera fa-xl" rol="button" role="button" onClick={ () => toggleLightbox(attach) }></i> : null 
                    });
                    found = true;
                }
            });
            if (!found) {
                data.push({ ...def, 
                    index: attachments.length + index,
                    isDefault: true, 
                    def: <i className="fas fa-check-circle fa-xl"></i>,
                });
                setUnsavedDefault(true);
                index++;
            }
        });

        attachments.forEach((def, i) => {
            let existing = _.find(defaultAttach, { name: def.name });
            if (!existing) {
                data.push({ ...def, index: i, images: (def.imageUrl) ? <i className="fa-solid fa-camera fa-xl" role="button" onClick={ () => toggleLightbox(def) }></i> : null });
            }
        });

        setData(data);     
	}, [attachments]);


    /* Handlers --------------------------*/

    const handleAddNew = () => {
		let location = toolbox.modifyPath(pathname, seg+3, "attachment-form/0");
		history.push({ pathname: location });
    };
    
    /* Actions --------------------------*/
    
	const openForm = (item) => {
        let location = toolbox.modifyPath(pathname, seg+3, "attachment-form/" + (item.index + 1));

        // Save attachments array if there are default records not saved
        if (unsavedDefault) {
            let ordered = _.orderBy(data, ['index'], ['asc']);
            let attachmentsArray = ordered.map((attachment) => {
                let { index, def, images, ...attach } = attachment; 
                return attach;
            });
			dispatch(actions_attachments.saveAttachment(params.handle, props.id, attachmentsArray, props.source, props.prefix, field, (id) => {
				history.push({ pathname: location });
			}));
        } else {
            history.push({ pathname: location });
        }
	};

	const toggleLightbox = (attach) => {
		let imagesAlt = attach.imagesAlt?.map((imageUrl, index) => {
			return ({ src: imageSize(imageUrl, 'lg') });
		});
		if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources([
			{ src: imageSize(attach.imageUrl, 'lg') },
			...(attach.imagesAlt) ? imagesAlt : []
		]));
		dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	}

    // const toggleLightbox = (image) => {
    //     let images = _.orderBy(image, ['position'], ['asc']).map((img, index) => ({ src: imageSize(img.downloadUrl, 'lg') }));
	// 	if (!admin.lightbox_show) dispatch(actions_admin.lightboxSources(images));
	// 	dispatch(actions_admin.lightboxShow(!admin.lightbox_show));
	// }

    /* Constants --------------------------*/

    const columns = [
        ...(defaultAttach.length) ? [{ name: '', field: 'def', type: 'jsx', width: 5 }] : [],
        { name: 'Name', field: 'name', width: 40},
        { name: 'Expiration', field: 'expiration', type: 'date', format: 'MMM Do, YYYY', width: 35 },
        { name: '', field: 'images', type: 'jsx', width: 5 },
        { name: '', field: 'id', type: 'button', button: { name: <i className="fa-regular fa-gear fa-lg"></i>, className: 'btn-default btn-xs float-right' }, callback: openForm, width: 15 }
    ];

    return (
        <Ibox title={(props.title) ? props.title : "Attachments" }
            button={(props.button) ? props.button : '+ Attachment'}
            button_callback={handleAddNew}
            button_disabled={!user_permissions.ASSETS_CREATE && !user_permissions.ASSETS_EDIT}
        >
            <Table id="attachments-tab"
                data={ data }
                columns={columns}
                on_focus={(target) => props.setFocused(target)}
                // order_by={{ fields: ['name'], direction: ['asc'] }}
                style={{ minHeight: '330px' }}
            >
            </Table>

            { admin.flutter_keyboard &&
                <div style={{ paddingBottom: '55vh' }}></div>
            }
        </Ibox>
    );
};

export default AttachmentsTab;