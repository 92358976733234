import React, { memo } from 'react';
import { Spinner } from 'enspire-manager-framework';
import Ripples, { createRipples } from 'react-ripples';

export const Widget =  memo(function Widget(props) {

	var cursor = (props.onClick) ? 'pointer' : 'auto';
	var color_class;
	switch (props.color_number) {
		case 0: color_class = 'bg-muted'; break;
		case 1: color_class = 'bg-primary'; break;
		case 2: color_class = 'bg-success'; break;
		case 3: color_class = 'bg-info'; break;
		case 4: color_class = 'bg-warning'; break;
		case 5: color_class = 'bg-danger'; break;
		case 6: color_class = 'bg-dark'; break;
		case 7: color_class = 'bg-light'; break;
		case 8: color_class = 'bg-white'; break;
		// default : color_class = 'bg-color-' + props.color_number;
	}

	var spinnerColor = (props.spinnerColor) ? props.spinnerColor : '';

	const MyRipples = createRipples({
		color: (props.active || props.color_number == 8) ? 'lightGrey' : 'white',
		during: 1800,
	});

	return (
		<>
			<div className={ props.grid }>
				<MyRipples>
					<div className={ `widget style1 m-0 ${props.className} ${(!props.active) ? color_class : 'bg-primary' }`} 
						style={{ cursor: cursor, minHeight: '110px', color: 'white',
						...(props.active) ? { color: 'white' } : (props.color) ? { color: props.color } : { color: 'white'  },
						...(!props.active) ? (props.backgroundColor) ? { backgroundColor: props.backgroundColor } : {} : {}, 
						}} 
						onClick={ () => {
							setTimeout(() => {
								props.onClick(props.value) 
							}, 250);
						}}
					>
						{ props.fetching
							? <Spinner center color={ spinnerColor } />
							: <>
								<div className="row">
									<div className={`col-2 mb-2`}>
										{ typeof props.icon == 'string' &&
											<i className={ props.icon }></i>
										}
										{ React.isValidElement(props.icon) &&
											<>{ props.icon }</>
										}
									</div>
									<div className="col-10 text-right" style={{ fontSize: '14px' }}>
										<span>{ props.text }</span>
									</div>
								</div>
								<div className="row">
									<div className="col-12 text-right">
										<h2 className="font-bold">{ props.title }</h2>
									</div>
								</div>
							</>
						}
					</div>
				</MyRipples>
			</div>
		</>
	);
});
